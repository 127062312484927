import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  useAdminEpicEpicIdGet,
  AdminEpicPostPutRequest,
  useAdminEpicEpicIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { cleanObject } from "@utils/cleanObject";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import CardsTable from "./CardsTable";
import Grid from "@mui/system/Unstable_Grid";
import { Loader } from "@components/crud/Loader";
export const EpicEdit = () => {
  const { epicId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { data: epic, isLoading: epicLoading } = useAdminEpicEpicIdGet(epicId!);

  const {
    control,
    getValues,
    setValue,
    reset,
    formState: { isValid }
  } = form;
  const { mutate: save, isLoading: isSaving } = useAdminEpicEpicIdPut();

  const onSave = () => {
    const filteredValues = cleanObject(getValues());
    const issues = Object.keys(filteredValues)
      .filter((key) => key.endsWith("_card") && filteredValues[key])
      .map((cardKey) => {
        const jiraIssueId = cardKey.split("_")[0];
        const platformKey = `${jiraIssueId}_platform`;
        return {
          jiraIssueId,
          platform: filteredValues[platformKey]
        };
      });

    const data: AdminEpicPostPutRequest = {
      name: filteredValues.name,
      description: filteredValues.description,
      issues: issues
    };
    save(
      {
        epicId: epicId!,
        data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Epic Edited successfully!", {
            variant: "success"
          });

          window.history.back();
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Epic!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  useEffect(() => {
    if (epic?.data) {
      const cards =
        epic.data?.issues &&
        epic.data?.issues.reduce((acc, issue) => {
          acc[`${issue.jiraIssueId}_card`] = true;
          acc[`${issue.jiraIssueId}_platform`] = issue.platform;
          return acc;
        }, {});
      reset({
        name: epic.data.name,
        description: epic.data.description,
        ...cards
      });
    }
  }, [epic]);
  return (
    <Container>
      <Toolbar title="Edit Epic" />
      <Loader isLoading={epicLoading}>
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid xs={12} data-testid="epic-name">
              <FormInput
                name="name"
                onChange={(e) => {
                  setValue(
                    "name",
                    capitalizeEveryWord(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
                control={control}
                label="Epic Name"
                required
                rules={{
                  required: "Epic Name Is Required"
                }}
                type="text"
              />
            </Grid>
            <Grid xs={12} data-testid="epic-description">
              <FormInput
                name="description"
                control={control}
                label="Description"
                required
                rules={{
                  required: "Description Is Required"
                }}
                multiline
                rows={5}
                type="text"
              />
            </Grid>
            <FormProvider {...form}>
              <Grid xs={12}>
                <CardsTable epicId={epicId!} />
              </Grid>
            </FormProvider>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => window.history.back()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
