import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ContactResponse,
  ModelOrganizationContact,
  useAdminCrmContactContactIdDelete,
  useAdminCrmContactContactIdGet,
  useAdminCrmOrgContactContactIdDelete,
  useAdminCrmOrgContactContactIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { ContactDetailsForm } from "./ContactDetailsForm";
import { Form } from "@components/crud/Form";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

export const ContactView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { contactId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.contacts", "DELETE");
      const edit = await checkPermission("crm.contacts", "EDIT");
      const view = await checkPermission("crm.contacts", "VIEW");
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  const { reset } = form;

  const [relatedAccounts, setRelatedAccounts] = useState<
    {
      accountId: string;
      account: string;
      jobTitle: string;
      isPrimaryContact: boolean;
      workLocation?: string;
      workPhoneType?: string;
      workPhone?: string;
      workEmail?: string;
    }[]
  >([]);

  const [homeLocationSameAs, setHomeLocationSameAs] = useState<string | null>(
    null
  );
  const [isHomeLocationSameAs, setIsHomeLocationSameAs] = useState(false);

  const [relatedLinks, setRelatedLinks] = useState<
    {
      url: string;
      name: string;
      otherName: string;
    }[]
  >([]);

  const { data: contactDetails, isLoading: isLoading } = organizationId
    ? useAdminCrmOrgContactContactIdGet(contactId!)
    : useAdminCrmContactContactIdGet(contactId!);
  useEffect(() => {
    if (contactDetails?.data) {
      const contact = contactDetails.data as
        | ModelOrganizationContact
        | ContactResponse;
      let locationSameAs: string | undefined;
      for (const acc of contact.accounts || []) {
        if (acc.account?.officeAddress === contact.location) {
          locationSameAs = acc.accountId;
          break;
        }
      }
      reset({
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
        suffix: contact.suffix,
        homeLocation: contact.location,
        homePhone: contact.phone,
        homeWhatsappNumber: contact.whatsappNumber,
        personalEmail: contact.email,
        isHomeLocationSameAs: !!locationSameAs
      });
      setIsHomeLocationSameAs(!!locationSameAs);
      setHomeLocationSameAs(locationSameAs as string);
      setRelatedLinks(
        contact.links?.map((l) => ({
          url: l.link || "",
          name: l.type || "",
          otherName: l.altName || ""
        })) || []
      );
      setRelatedAccounts(
        contact.accounts?.map((acc) => ({
          accountId: acc.accountId!,
          account: acc?.account?.name,
          jobTitle: acc.jobTitle!,
          isPrimaryContact: !!acc.isPrimary,
          workLocation: acc?.account?.officeAddress,
          workPhoneType: acc?.workPhoneType || "MOBILE",
          workPhone: acc?.workPhone || "",
          workEmail: acc?.workEmail || ""
        })) || []
      );
    }
  }, [contactDetails]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = organizationId
    ? useAdminCrmOrgContactContactIdDelete()
    : useAdminCrmContactContactIdDelete();

  const onContactDelete = async () => {
    try {
      await deleteAsync({ contactId: contactId as string });
      enqueueSnackbar("Contact Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/crm/contacts");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Contact.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Contact"
        backBtnClick={() => {
          navigate("/crm/contacts");
        }}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/crm/contacts/${contactId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () => setOpenDeleteDialog(true)
        })}
      />
      <Loader isLoading={isDeleting || isLoading}>
        <Form>
          <ContactDetailsForm
            disabled
            form={form}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
            relatedLinks={relatedLinks}
            setRelatedLinks={setRelatedLinks}
            homeLocationSameAs={homeLocationSameAs}
            setHomeLocationSameAs={setHomeLocationSameAs}
            isHomeLocationSameAs={isHomeLocationSameAs}
            setIsHomeLocationSameAs={setIsHomeLocationSameAs}
            organizationId={organizationId}
          />
        </Form>
      </Loader>
      <ConfirmationDialog
        title="Delete Contact?"
        body="Are you sure you want to delete this Contact?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onContactDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
