/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Backdrop,
  Box,
  Container,
  InputAdornment,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "@components/crud/Form";
import { FormInput } from "@components/FormInput";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CRM_ACCOUNT_CATEGORIES,
  ORGANIZATION_TYPE_OPTIONS,
  SUFFIX_OPTIONS
} from "@utils/constants";
import { FormSelect } from "@components/FormSelect";
import {
  ModelCountry,
  usePostAdminCrmAccount,
  usePostAdminCrmOrgAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { getCountries } from "@services/Network";
import { Search } from "@mui/icons-material";
import { Footer } from "@components/crud/Footer";
import { SearchAddAccount } from "../../components/SearchAddAccount";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { capitalize } from "@utils/capitalize";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { EMAIL_REGEX } from "@utils/validation";
import { enqueueSnackbar } from "notistack";
import { cleanObject } from "@utils/cleanObject";
import { AccountCallerDetails } from "@templates/CallEmailTemplate";
import colors from "theme/colors";

const libraries = ["places"] as "places"[];
const autocompleteService = { current: null } as any;
const autocompleteService1 = { current: null } as any;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  maxHeight: "calc(100vh - 64px)",
  overflowY: "auto",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const QuickAddAccount = ({
  onClose,
  account,
  onSave,
  addLoadScript = true,
  organizationId
}: {
  onClose: () => void;
  account?: AccountCallerDetails;
  onSave: (resp, values?) => void;
  addLoadScript?: boolean;
  organizationId?: string;
}) => {
  const navigate = useNavigate();
  const {
    control,
    setValue,
    handleSubmit,
    clearErrors,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "onBlur"
  });

  const [disabled, setDisabled] = useState(false);
  const countries = getCountries();
  const [country, setCountry] = useState<ModelCountry | undefined>(undefined);
  const [addNewAccount, setAddNewAccount] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [parentId, setParentId] = useState<string | null>(null);
  const [officeAddessSelected, setOfficeAddessSelected] = useState(true);
  const [homeAddessSelected, setHomeAddessSelected] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const selectAddressSuggestion = (
    place,
    addressType: "officeAddress" | "homeAddress"
  ) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue(addressType, address);
    if (
      countries &&
      countries.data &&
      countries.data.find((c) => c.countryId == country?.short_name)
    ) {
      setCountry(
        countries.data.find((c) => c.countryId == country?.short_name)
      );
    }
  };
  const { mutate: save, isLoading: isSaving } = !organizationId
    ? usePostAdminCrmAccount()
    : usePostAdminCrmOrgAccount();
  const saveHandler = () => async (formValues) => {
    const data = {
      name: formValues.name,
      parentId: parentId,
      type: formValues.type,
      category: formValues.category,
      officeAddress: formValues.officeAddress,
      email: formValues.email,
      website: formValues.website,
      contact: {
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
        suffix: formValues.suffix,
        email: formValues.personalEmail,
        phone: formValues.phone,
        whatsappNumber: formValues.whatsappNumber,
        isPrimary: true,
        jobTitle: ""
      },
      ...(organizationId && {
        organizationId: organizationId
      })
    };
    save(
      {
        data: cleanObject(data)
      },
      {
        onSuccess: (resp) => {
          enqueueSnackbar("Account Created successfully!", {
            variant: "success"
          });
          onSave(resp.data, data);
          onClose();
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Account!", {
            variant: "error"
          });
        }
      }
    );
  };

  const selectedAccount = (
    selectedAccount,
    accountSelected,
    newAccountSelected
  ) => {
    setAddNewAccount(newAccountSelected);
    if (accountSelected) {
      setDisabled(true);
      clearErrors();
      onClose();
      navigate(
        `/crm/accounts/${selectedAccount.accountId}?tab=Account+Details`
      );
    }
  };

  useEffect(() => {
    if (account) {
      if (!account.accountId) setAddNewAccount(true);
      if (account.contact?.contactPhone)
        setValue("phone", account?.contact?.contactPhone);
      if (account?.accountName) setValue("name", account.accountName);
      if (account?.location) setValue("officeAddress", account.location);
      if (account.contact?.contactName) {
        setValue("firstName", account.contact.contactName.split(" ")[0]);
        setValue("lastName", account.contact.contactName.split(" ")[1]);
      }
    }
  }, [account]);

  useEffect(() => {
    if (trigger) {
      if (officeAddessSelected) {
        trigger("officeAddress");
      }
      if (homeAddessSelected) {
        trigger("homeAddress");
      }
    }
  }, [officeAddessSelected, homeAddessSelected]);

  useEffect(() => {
    setRefreshKey((prev) => prev + 1);
  }, []);

  const QuickAddForm = () => {
    return (
      <>
        <Grid xs={12}>
          <Autocomplete
            key={"officeAddress"}
            className="address-autocomplete"
            onLoad={(autocomplete) => {
              autocompleteService.current = autocomplete;
            }}
            onPlaceChanged={() => {
              if (autocompleteService.current) {
                const place = autocompleteService.current.getPlace();
                selectAddressSuggestion(place, "officeAddress");
                setOfficeAddessSelected(true);
              }
            }}
          >
            <FormInput
              name="officeAddress"
              control={control}
              rules={{
                required: "Office Address is required",
                validate: () => {
                  return !officeAddessSelected
                    ? "The address entered is invalid, please make a recommended selection"
                    : undefined;
                }
              }}
              disabled={disabled || !addNewAccount}
              label="Office Address"
              required
              type="text"
              onChange={() => {
                setOfficeAddessSelected(false);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </Autocomplete>
        </Grid>
        <Grid xs={6}>
          <FormInput
            label="Email"
            name="email"
            type="text"
            control={control}
            disabled={disabled || !addNewAccount}
            required
            rules={{
              reuiqred: { value: true, message: "Email is required" },
              validate: (value) => {
                if (!EMAIL_REGEX.test(value) && value && value != "") {
                  return "Invalid Email";
                }
                if (!value) return "Email is required";
              }
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            label="Website"
            name="website"
            type="text"
            control={control}
            disabled={disabled || !addNewAccount}
          />
        </Grid>
        <Grid xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              letterSpacing: "0.1em",
              textAlign: "left",
              color: "#000000",
              opacity: 0.7,
              textTransform: "uppercase"
            }}
          >
            Primary Contact
          </Typography>
          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid xs={3}>
          <FormInput
            label="First Name"
            name="firstName"
            type="text"
            control={control}
            disabled={disabled || !addNewAccount}
            required
            rules={{ required: "First Name is required" }}
            onChange={(e) => {
              //@ts-ignore
              setValue("firstName", capitalize(e?.target?.value));
            }}
          />
        </Grid>
        <Grid xs={3}>
          <FormInput
            label="Middle Name"
            name="middleName"
            type="text"
            control={control}
            disabled={disabled || !addNewAccount}
            onChange={(e) => {
              //@ts-ignore
              setValue("middleName", capitalize(e?.target?.value));
            }}
          />
        </Grid>
        <Grid xs={3}>
          <FormInput
            label="Last Name"
            name="lastName"
            type="text"
            control={control}
            disabled={disabled || !addNewAccount}
            required
            rules={{ required: "Last Name is required" }}
            onChange={(e) => {
              //@ts-ignore
              setValue("lastName", capitalize(e?.target?.value));
            }}
          />
        </Grid>
        <Grid xs={3}>
          <FormSelect
            label="Suffix"
            name="suffix"
            control={control}
            disabled={disabled || !addNewAccount}
            options={SUFFIX_OPTIONS}
          />
        </Grid>
        <Grid xs={12}>
          <Autocomplete
            key={"homeAddress"}
            className="address-autocomplete"
            onLoad={(autocomplete) => {
              autocompleteService1.current = autocomplete;
            }}
            onPlaceChanged={() => {
              if (autocompleteService1.current) {
                const place = autocompleteService1.current.getPlace();
                selectAddressSuggestion(place, "homeAddress");
                setHomeAddessSelected(true);
              }
            }}
          >
            <FormInput
              name="homeAddress"
              control={control}
              label="Home location"
              type="text"
              disabled={disabled || !addNewAccount}
              required
              rules={{
                required: "Home Address is required",
                validate: () => {
                  return !homeAddessSelected
                    ? "The address entered is invalid, please make a recommended selection"
                    : undefined;
                }
              }}
              onChange={() => {
                setHomeAddessSelected(false);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </Autocomplete>
        </Grid>
        <Grid xs={6}>
          <FormInput
            control={control}
            label="Personal Email"
            name="personalEmail"
            type="text"
            disabled={disabled || !addNewAccount}
            required
            rules={{
              required: "Personal Email is required",
              validate: (value) => {
                if (!EMAIL_REGEX.test(value) && value && value != "") {
                  return "Invalid Email";
                }
              }
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            control={control}
            label="Phone"
            name="phone"
            type="tel"
            disabled={disabled || !addNewAccount}
            required
            rules={{
              required: { value: true, message: "Phone is required" }
            }}
            country={country}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            control={control}
            label="WhatsApp Number"
            name="whatsappNumber"
            type="tel"
            disabled={disabled || !addNewAccount}
            country={country}
          />
        </Grid>
      </>
    );
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Quickly Add Account
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenCancelDialog(true)}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Form>
            <Grid container spacing={3}>
              <Grid xs={6}>
                {addNewAccount && (
                  <FormInput
                    name="name"
                    label="Name"
                    type="text"
                    control={control}
                    disabled={disabled}
                    required
                    rules={{ required: "Name is required" }}
                    capitalizeWords
                  />
                )}
                {!addNewAccount && (
                  <SearchAddAccount
                    accountSelected={selectedAccount}
                    showAddAccountOption={true}
                    label="Name"
                    required={true}
                    disabled={disabled}
                    parentId={parentId}
                    organizationId={organizationId}
                  />
                )}
              </Grid>
              <Grid xs={6}>
                {!disabled ? (
                  <SearchAddAccount
                    accountSelected={(selectedAccount) => {
                      if (selectedAccount?.accountId)
                        setParentId(selectedAccount.accountId);
                    }}
                    label="Parent"
                    required={false}
                    disabled={disabled}
                    onClear={() => {
                      setParentId(null);
                    }}
                    organizationId={organizationId}
                  />
                ) : (
                  <FormInput
                    name="parent"
                    control={control}
                    type="text"
                    label="Parent"
                    disabled
                  />
                )}
              </Grid>
              <Grid xs={6}>
                <FormSelect
                  control={control}
                  name="category"
                  type="text"
                  label="Category"
                  required={true}
                  disabled={disabled || !addNewAccount}
                  options={CRM_ACCOUNT_CATEGORIES}
                  rules={{
                    required: "Category is required"
                  }}
                />
              </Grid>
              <Grid xs={6}>
                <FormSelect
                  control={control}
                  name="type"
                  type="text"
                  label="Type"
                  required={true}
                  disabled={disabled || !addNewAccount}
                  options={ORGANIZATION_TYPE_OPTIONS}
                  rules={{
                    required: "Type  is required"
                  }}
                />
              </Grid>
              {addLoadScript ? (
                <LoadScript
                  key={refreshKey}
                  googleMapsApiKey={
                    import.meta.env.VITE_APP_MAP_API_KEY as string
                  }
                  libraries={libraries}
                  loadingElement={<LoadingSpinner />}
                >
                  {QuickAddForm()}
                </LoadScript>
              ) : (
                QuickAddForm()
              )}
            </Grid>
          </Form>
          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            saveBtnClick={() => {
              if (!disabled) {
                handleSubmit(saveHandler())();
              } else {
                navigate(`/crm/accounts/${1}?tab=Account+Details`);
              }
            }}
            saveBtnLabel={!disabled ? "Save" : "View Account"}
            isDisabled={(!disabled && !isValid) || isSaving}
            isLoading={isSaving}
          />
        </StyledBox>
      </Container>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => onClose()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Backdrop>
  );
};
