/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@components/Button";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowOrderChangeParams,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useEffect, useRef, useState } from "react";
import { AddIcon, RowOrderingIcon } from "./Icons";
import { Container } from "./crud/Container";
import { Footer } from "./crud/Footer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  organizationAtom,
  selectedFilterAtom,
  selectedPlatformAtom,
  selectedReleaseAtom,
  selectedTesterAtom
} from "../recoil/auth";
import { useNavigate } from "react-router-dom";
import { Loader } from "./crud/Loader";
import { FilterTreeSearchComponent } from "./FilterTreeSearchComponent";

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));

const AddBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "&.table-view": {
    borderRadius: "0px",

    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: theme.palette.white.main,
      boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, .08)",
      marginLeft: "5px",

      "& .MuiDataGrid-cell": {
        borderBottom: "none",

        ":first-of-type": {
          padding: "0 3px"
        },

        ":nth-of-type(2)": {
          "&.action-cell": {
            padding: "0 10px"
          },
          padding: "0 14px"
        },

        ":nth-of-type(3)": {
          padding: "0 14px 0 18px"
        }
      }
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      fontWeight: 600,
      boxShadow: "none",

      "& .MuiDataGrid-columnHeader": {
        ":first-of-type": {
          padding: "0 7px"
        }
      }
    },

    "& .MuiDataGrid-columnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: "0px"
    },

    "& .MuiDataGrid-columnHeader": {
      "&. MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600
      },

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-row": {
      boxShadow: "inset 0px -1px 0px #E2E8F0"
    },

    "& .MuiDataGrid-rowReorderCellContainer": {
      ":first-of-type": {
        padding: "0 0 0 14px !important"
      }
    },

    "& .MuiDataGrid-cell": {
      borderBottom: "none",

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-footerContainer": {
      justifyContent: "flex-start",
      backgroundColor: theme.palette.white.secondary
    },
    "& .MuiDataGrid-overlayWrapper": {
      fontSize: "16px",
      fontWeight: "600"
    },
    "& .multiple-line-cell": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center"
    }
  }
}));

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  height: "200px"
}));

const DEFAULT_PAGE_SIZE = 20;

type FilterOption = {
  label: string;
  value?: string;
  children?: FilterOption[];
};

interface TableViewProps extends Omit<DataGridProProps, "rows"> {
  columns: any[];
  customActions?: any[];
  defaultSort?: GridSortModel;
  filterConfig?: {
    field: string;
    placeholderOption?: {
      value: string;
      label: string;
    };
    options: FilterOption[];
    defaultValue?: string;
    label?: string;
  };
  platformConfig?: {
    field: string;
    placeholderOption?: {
      value: string;
      label: string;
    };
    options: FilterOption[];
    defaultValue?: string;
    label?: string;
  };
  releaseConfig?: {
    field: string;
    placeholderOption?: {
      value: string;
      label: string;
    };
    options: FilterOption[];
    defaultValue?: string;
    label?: string;
  };
  testerConfig?: {
    field: string;
    placeholderOption?: {
      value: string;
      label: string;
    };
    options: FilterOption[];
    defaultValue?: string;
    label?: string;
  };
  getRowId?: GridRowIdGetter<GridValidRowModel>;
  hasActionColumn?: boolean;
  hideFilter?: boolean;
  hidePlatformFilter?: boolean;
  hideReleaseFilter?: boolean;
  hideTesterFilter?: boolean;
  hideFooter?: boolean;
  hideLabelContainer?: boolean;
  hideSortButton?: boolean;
  hideSubmitFooter?: boolean;
  hideToolbar?: boolean;
  isDeleteDisabled?: (row: unknown) => boolean;
  isEditDisabled?: (row: never) => boolean;
  isSubmitting?: boolean;
  onAdd?: () => void;
  onCancel?: () => void;
  onDelete?: (row: GridRenderCellParams) => void;
  onEdit?: (row: GridRenderCellParams) => void;
  onFilterChange?: (value: string) => void;
  onRowOrderChange?: (params: GridRowOrderChangeParams) => void;
  onSortButtonClick?: () => void;
  onSubmit?: () => void;
  onView?: (row: GridRenderCellParams) => void;
  refreshKey?: number;
  rowReordering?: boolean;
  rows?: readonly any[];
  searchable?: boolean;
  title: string;
  useGet: (params, options) => any;
  params?: any;
  options?: any;
  formSelectType: string;
  backButton: boolean;
  action?: string;
  sortingMode?: "client" | "server";
  customFilter?: any;
  addBtnText?: string;
  setId?: (id: string) => void;
  featureId?: string;
  platformId?: string;
  releaseId?: string;
  testerId?: string;
  statusFilters?: string[];
  setSelectedPlatform?: (platform: string) => void;
  setSelectedRelease?: (platform: string) => void;
  setSelectedTester?: (platform: string) => void;
  noRowsView?: any;
  showCustomNoRows?: boolean;
  tableKey?: string;
  includeChildren?: boolean;
  disabledInfiniteScroll?: boolean;
  setRows?: any;
  resourceId?: string;
  cardType?: string;
  defaultSearch?: string;
}

export const TableViewInfinite = ({
  columns,
  customActions,
  defaultSort,
  filterConfig,
  platformConfig,
  releaseConfig,
  testerConfig,
  getRowId,
  hasActionColumn,
  hideFilter,
  hidePlatformFilter,
  hideReleaseFilter,
  hideTesterFilter,
  hideFooter,
  hideLabelContainer,
  hideSortButton,
  hideSubmitFooter,
  hideToolbar,
  isDeleteDisabled,
  isEditDisabled,
  isSubmitting,
  onAdd,
  onCancel,
  onDelete,
  onEdit,
  onFilterChange,
  onRowOrderChange,
  onSortButtonClick,
  onSubmit,
  onView,
  refreshKey,
  rowReordering,
  rows,
  searchable,
  title,
  useGet,
  formSelectType,
  backButton,
  action,
  sortingMode,
  customFilter,
  addBtnText,
  setId,
  featureId,
  platformId,
  testerId,
  releaseId,
  statusFilters,
  setSelectedPlatform,
  setSelectedRelease,
  setSelectedTester,
  noRowsView,
  showCustomNoRows,
  tableKey,
  includeChildren,
  disabledInfiniteScroll,
  setRows,
  resourceId,
  cardType,
  defaultSearch,
  ...dataGridProps
}: TableViewProps) => {
  const setSelectedPlatformState = useSetRecoilState(selectedPlatformAtom);
  const setSelectedReleaseState = useSetRecoilState(selectedReleaseAtom);
  const setSelectedTesterState = useSetRecoilState(selectedTesterAtom);
  const setSelectedFilterState = useSetRecoilState(selectedFilterAtom);
  const selectedFilterValue = useRecoilValue(selectedFilterAtom);
  const pageSize = DEFAULT_PAGE_SIZE;
  const [pageNo, setPageNo] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [prevQuery, setPrevQuery] = React.useState<{
    pageNo: number;
    pageSize: number;
    sortField?: string;
    sortDirection?: string;
    textSearch?: string;
    organizationId?: string;
    action?: string;
  }>({ pageNo, pageSize, action });
  const [filter, setFilter] = React.useState(
    filterConfig?.defaultValue || filterConfig?.placeholderOption?.value || ""
  );
  const [dataAppended, setDataAppended] = useState([]);

  const [platform, setPlatform] = React.useState(
    platformConfig?.defaultValue ||
      platformConfig?.placeholderOption?.value ||
      platformId ||
      "ALL"
  );

  const [release, setRelease] = React.useState(
    releaseConfig?.defaultValue ||
      releaseConfig?.placeholderOption?.value ||
      releaseId ||
      ""
  );
  const [tester, setTester] = React.useState(
    testerConfig?.defaultValue ||
      testerConfig?.placeholderOption?.value ||
      testerId ||
      ""
  );
  const [textSearch, setTextSearch] = React.useState("");
  const [sortModel, setSortModel] = React.useState<GridSortModel>(
    defaultSort || []
  );
  const gridApiRef = useGridApiRef();
  const organizationId = useRecoilValue(organizationAtom);
  React.useEffect(() => {
    if (releaseConfig?.defaultValue) {
      setRelease(releaseConfig.defaultValue);
    }
  }, [releaseConfig]);

  React.useEffect(() => {
    if (release) {
      setSelectedReleaseState(release);
      if (setSelectedRelease) setSelectedRelease(release);
    }
  }, [release]);

  React.useEffect(() => {
    if (tester) {
      setSelectedTesterState(tester);
      if (setSelectedTester) setSelectedTester(tester);
    }
  }, [tester]);

  React.useEffect(() => {
    setPageNo(0);
    setDataAppended([]);
  }, [defaultSearch, resourceId, cardType]);

  React.useLayoutEffect(() => {
    if (gridApiRef.current && gridApiRef.current.resetRowHeights) {
      gridApiRef.current.resetRowHeights();
    }
  }, [gridApiRef]);
  const query = React.useMemo(() => {
    const buildQuery = {
      pageNo,
      pageSize,
      organizationId: organizationId,
      action,
      resourceId
    } as {
      pageNo: number;
      pageSize: number;
      sortField?: string;
      sortDirection?: string;
      textSearch?: string;
      organizationId?: string;
      action?: string;
      resourceId?: string;
    };
    if (filterConfig && filter !== filterConfig?.placeholderOption?.value) {
      buildQuery[filterConfig?.field] = filter;
      if (buildQuery[filterConfig?.field] !== prevQuery[filterConfig?.field])
        setPageNo(0);
    }
    if (
      (platformConfig &&
        platform !== platformConfig?.placeholderOption?.value) ||
      (platformId && platformId !== "")
    ) {
      buildQuery[platformConfig?.field || "platform"] = platform;
      if (
        buildQuery[platformConfig?.field || "platform"] !==
        prevQuery[platformConfig?.field || "platform"]
      )
        setPageNo(0);
    }

    if (
      (releaseConfig && release !== releaseConfig?.placeholderOption?.value) ||
      (releaseId && releaseId !== "")
    ) {
      buildQuery[releaseConfig?.field || "release"] = release;
      if (
        buildQuery[releaseConfig?.field || "release"] !==
        prevQuery[releaseConfig?.field || "release"]
      )
        setPageNo(0);
    }

    if (
      (testerConfig &&
        tester !== testerConfig?.placeholderOption?.value &&
        tester !== "") ||
      (testerId && testerId !== "")
    ) {
      buildQuery[testerConfig?.field || "testerId"] = tester;
      if (
        buildQuery[testerConfig?.field || "testerId"] !==
        prevQuery[testerConfig?.field || "testerId"]
      )
        setPageNo(0);
    }

    if (resourceId) {
      buildQuery["resourceId"] = resourceId;
    }

    if (cardType) {
      buildQuery["type"] = cardType;
    }

    if (textSearch) {
      buildQuery.textSearch = textSearch;
    }
    if (defaultSearch) {
      buildQuery.textSearch = defaultSearch;
    }

    if (sortModel?.length && sortModel[0].sort && sortModel[0].field) {
      buildQuery.sortField = sortModel[0].field;
      buildQuery.sortDirection = sortModel[0].sort;
    }
    if (featureId) {
      buildQuery["featureId"] = featureId;
    }
    if (includeChildren) {
      buildQuery["includeChildren"] = includeChildren;
    }
    if (statusFilters) {
      if (statusFilters.length) buildQuery["filter"] = statusFilters;
      if (prevQuery["filter"] !== buildQuery["filter"]) setPageNo(0);
    }
    return buildQuery;
  }, [
    pageNo,
    pageSize,
    filter,
    textSearch,
    sortModel,
    platform,
    featureId,
    statusFilters,
    release,
    tester,
    defaultSearch,
    resourceId,
    cardType
  ]);

  if (!dataGridProps?.initialState) {
    dataGridProps.initialState = {};
  }
  useEffect(() => {
    if (platformId) setPlatform(platformId as string);
  }, [platformId]);

  useEffect(() => {
    setTester(testerId as string);
  }, [testerId]);

  useEffect(() => {
    if (releaseId) setRelease(releaseId as string);
  }, [releaseId]);

  const { isFetching, data, refetch } = useGet(query, {
    query: {
      queryKey: [
        pageSize,
        pageNo,
        filter,
        textSearch,
        sortModel,
        refreshKey,
        platform,
        featureId,
        includeChildren,
        release,
        tester,
        resourceId,
        cardType
      ],
      refetchOnWindowFocus: false
    }
  });
  useEffect(() => {
    refetch();
    setPrevQuery(query);
  }, [query]);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (
          hasMore &&
          first.isIntersecting &&
          (!isFetching || pageNo === 0) &&
          !disabledInfiniteScroll
        ) {
          setPageNo((page) => page + 1);
        }
      },
      {
        rootMargin: "20px 0px",
        threshold: 1.0 // entire target element is visible
      }
    )
  );
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (isFetching || !hasMore) currentObserver.disconnect();
    else if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [dataAppended]);

  useEffect(() => {
    if (!isFetching) {
      if (data.length < pageSize) setHasMore(false);
      if (pageNo === 0 && data.length >= pageSize) setHasMore(true);
      if (pageNo !== 0) setDataAppended((d) => d.concat(data));
      else setDataAppended(data);
    }
  }, [isFetching]);

  useEffect(() => {
    if (setRows) setRows(dataAppended);
  }, [dataAppended]);

  const actionColumn: GridColDef = React.useMemo(
    () => ({
      field: "action",
      headerName: "Actions",
      headerClassName: "action-header",
      cellClassName: "action-cell",
      minWidth: 150,
      flex: 0.12,
      sortable: false,
      renderCell: (params) => {
        const deleteDisabled = isDeleteDisabled
          ? isDeleteDisabled(params.row)
          : true;
        const editDisabled = isEditDisabled
          ? isEditDisabled(params.row as never)
          : false;
        return (
          <Box>
            {onView && (
              <IconButton
                data-testid="onViewTable"
                onClick={() => onView(params.row)}
              >
                <Visibility />
              </IconButton>
            )}
            {onEdit && !editDisabled && (
              <IconButton
                data-testid="onEditTable"
                onClick={() => onEdit(params.row)}
                disabled={editDisabled}
              >
                <Edit />
              </IconButton>
            )}
            {customActions?.map((action, index) => action(params, index))}
            {onDelete && !deleteDisabled && (
              <IconButton
                data-testid="onDeleteTable"
                onClick={() => onDelete(params.row)}
                disabled={deleteDisabled}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        );
      }
    }),
    []
  );

  const onSortModelChange = (model) => {
    setSortModel(model);
    setDataAppended([]);
    setPageNo(0);
  };

  const addClickHandler = () => {
    onAdd && onAdd();
  };

  const onFilter = (e) => {
    setFilter(e.target.value);
    onFilterChange && onFilterChange(e.target.value);
  };

  const saveClickHandler = () => {
    if (onSubmit) {
      onSubmit();
    }
  };
  const navigate = useNavigate();
  const handleBackToReportsClick = () => {
    navigate(`/reports`);
  };
  const SelectFieldOptions = React.useMemo(() => {
    const placeholderOption = filterConfig?.placeholderOption
      ? [filterConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(filterConfig?.options || [])];
  }, [columns, filterConfig]);

  const PlatformFieldOptions = React.useMemo(() => {
    const placeholderOption = platformConfig?.placeholderOption
      ? [platformConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(platformConfig?.options || [])];
  }, [columns, platformConfig]);

  const ReleaseFieldOptions = React.useMemo(() => {
    const placeholderOption = releaseConfig?.placeholderOption
      ? [releaseConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(releaseConfig?.options || [])];
  }, [columns, releaseConfig]);

  const TesterFieldOptions = React.useMemo(() => {
    const placeholderOption = testerConfig?.placeholderOption
      ? [testerConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(testerConfig?.options || [])];
  }, [columns, testerConfig]);
  const gridColumns = React.useMemo(() => {
    const newColumns: GridColDef[] = [];
    if (!rowReordering) {
      newColumns.push({
        field: "_",
        headerName: "",
        sortable: false,
        resizable: false,
        valueGetter: () => "",
        minWidth: 0,
        maxWidth: 0,
        width: 0
      });
    }
    if (hasActionColumn) {
      newColumns.push(actionColumn);
    }
    newColumns.push(...columns);

    return newColumns;
  }, [columns, hasActionColumn]);

  if (!dataGridProps?.initialState?.pinnedColumns && !rowReordering) {
    // automatically pin the first column or first two columns if the first column is an action column
    dataGridProps.initialState.pinnedColumns = {
      left: gridColumns
        .slice(0, hasActionColumn ? 3 : 2)
        .map((column) => column.field)
    };
  }
  if (dataGridProps?.pinnedColumns?.left && !rowReordering) {
    dataGridProps.initialState.pinnedColumns = {
      left: gridColumns
        .slice(0, dataGridProps.pinnedColumns.left.length + 1)
        .map((column) => column.field)
    };
    delete dataGridProps.pinnedColumns;
  }
  function noRowsOverlay() {
    const overlay = document.querySelector(".MuiDataGrid-overlayWrapper");
    if (overlay) {
      //@ts-ignore
      overlay.style.height = "200px";
      //@ts-ignore
      overlay.style.marginTop = "20px";
    }

    return (
      <StyledGridOverlay data-testid={"noRecordExist"}>
        <Box>No records exist</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <Container key={tableKey || "0"} id={tableKey || "0"}>
      {!hideToolbar && (
        <>
          <StyledToolbar>
            <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
              <Grid
                xs={12}
                md={"auto"}
                sx={{ flexGrow: { md: "1 !important" } }}
              >
                <Typography variant="h2">{title}</Typography>
              </Grid>
              {backButton && (
                <Grid sx={{ textAlign: "-webkit-right" }} xs={6}>
                  <Button
                    onClick={handleBackToReportsClick}
                    variant="admin-secondary"
                  >
                    Back to Reports
                  </Button>
                </Grid>
              )}
              <Grid
                xs={12}
                md={"auto"}
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={2}
              >
                {onAdd && (
                  <Grid>
                    <AddBtn
                      variant="admin-primary"
                      onClick={addClickHandler}
                      startIcon={<AddIcon sx={{ marginTop: "1.5px" }} />}
                    >
                      {addBtnText || "Add"}
                    </AddBtn>
                  </Grid>
                )}
                {!hideSortButton && (
                  <Grid>
                    <Button variant="admin-primary" onClick={onSortButtonClick}>
                      Sort
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </StyledToolbar>
          <StyledToolbar
            style={{ paddingTop: 0, marginTop: "-10px", paddingRight: "0px" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing="20px"
              width="100%"
            >
              {!hideLabelContainer && (
                <Grid style={{ marginRight: "-10px" }}>
                  <Typography variant="gridToolbarFilterLabel">
                    {filterConfig?.label || formSelectType}
                  </Typography>
                </Grid>
              )}
              {!hideFilter && (
                <Grid data-testid={"tableViewFilterConfig"}>
                  <FormSelect
                    name="view"
                    options={SelectFieldOptions}
                    value={filter}
                    onChange={onFilter}
                    sx={{
                      minWidth: 250
                    }}
                  />
                </Grid>
              )}
              {searchable && (
                <Grid flex={1}>
                  <SearchInput
                    placeholder="Search"
                    onChange={(event) => setTextSearch(event.target.value)}
                    sx={{
                      minWidth: 250,
                      height: "53px"
                    }}
                  />
                </Grid>
              )}
              {customFilter && (
                <Grid
                  xs={12}
                  data-testid={"tableViewCustomFilter"}
                  container
                  alignItems="center"
                  direction="row"
                  spacing="15px"
                >
                  <Grid
                    container
                    alignItems="center"
                    spacing="15px"
                    direction="row"
                    flexGrow={1}
                  >
                    {!hideReleaseFilter && releaseConfig && (
                      <Grid
                        minWidth="150px"
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <Grid>
                          <Typography variant="gridToolbarFilterLabel">
                            {releaseConfig?.label || formSelectType}
                          </Typography>
                        </Grid>
                        <Grid data-testid="releaseFilter">
                          <FormSelect
                            name="release"
                            isReleaseSelect
                            options={ReleaseFieldOptions}
                            value={release}
                            onChange={(e) => {
                              setRelease(e.target.value);
                              if (setSelectedRelease)
                                setSelectedRelease(e.target.value);
                            }}
                            sx={{
                              minWidth: 85
                              // color: "#3B6CF8"
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {!hideTesterFilter && testerConfig && (
                      <Grid
                        minWidth="150px"
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <Grid>
                          <Typography variant="gridToolbarFilterLabel">
                            {testerConfig?.label || formSelectType}
                          </Typography>
                        </Grid>
                        <Grid data-testid={"testerFilter"}>
                          <FormSelect
                            name="Tester"
                            options={TesterFieldOptions}
                            value={tester}
                            onChange={(e) => {
                              setTester(e.target.value);
                              // setSelectedPlatformState(e.target.value);
                              if (setSelectedTester)
                                setSelectedTester(e.target.value);
                            }}
                            sx={{
                              minWidth: 85
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {!hidePlatformFilter && (
                      <Grid
                        minWidth="150px"
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <Grid>
                          <Typography variant="gridToolbarFilterLabel">
                            {platformConfig?.label || formSelectType}
                          </Typography>
                        </Grid>
                        <Grid data-testid={"platformFilter"}>
                          <FormSelect
                            name="platform"
                            options={PlatformFieldOptions}
                            value={platform}
                            onChange={(e) => {
                              setPlatform(e.target.value);
                              setSelectedPlatformState(e.target.value);
                              if (setSelectedPlatform)
                                setSelectedPlatform(e.target.value);
                            }}
                            sx={{
                              minWidth: 85
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {/* <Grid flex={1}> */}
                    {customFilter === "FEATURE" && (
                      <Grid flex={2} data-testid={"featureFilter"}>
                        <FilterTreeSearchComponent
                          platform={platform}
                          onFilterChange={(val) => {
                            setFilter(val);
                            setSelectedFilterState(val);
                            if (setId) setId(val);
                          }}
                          onClearFilter={() => {
                            setFilter("");
                            setSelectedFilterState("");
                            if (setId) setId("");
                          }}
                          release={release ? release : undefined}
                          selectedValue={selectedFilterValue}
                          style={{
                            minHeight: "53px"
                          }}
                        />
                      </Grid>
                    )}
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledToolbar>
        </>
      )}
      {!showCustomNoRows || (dataAppended && dataAppended.length > 0) ? (
        <StyledDataGrid
          data-testid={"tableViewDataGridInfinite"}
          apiRef={gridApiRef}
          className="table-view"
          rows={dataAppended || rows}
          columns={gridColumns}
          disableColumnMenu
          onSortModelChange={onSortModelChange}
          sortingMode={sortingMode}
          getRowId={getRowId}
          hideFooter={hideFooter}
          components={{
            RowReorderIcon: RowOrderingIcon
          }}
          rowReordering={rowReordering}
          onRowOrderChange={onRowOrderChange}
          {...dataGridProps}
          {...(!isFetching &&
            dataAppended &&
            dataAppended.length == 0 && {
              slots: {
                noRowsOverlay: noRowsOverlay,
                noResultsOverlay: noRowsOverlay
              }
            })}
        />
      ) : noRowsView ? (
        noRowsView
      ) : (
        noRowsOverlay()
      )}
      <Loader isLoading={isFetching}>
        <div ref={setLastElement}></div>
      </Loader>

      {!hideSubmitFooter && (
        <Footer
          cancelBtnClick={onCancel}
          saveBtnClick={saveClickHandler}
          isLoading={!!isSubmitting}
          isDisabled={!!isSubmitting}
        />
      )}
    </Container>
  );
};

TableViewInfinite.defaultProps = {
  isDeleteDisabled: () => false,
  hasActionColumn: true,
  searchable: true,
  hideFilter: false,
  hideFooter: false,
  hideSubmitFooter: true,
  rowReordering: false,
  isSubmitting: false,
  hideSortButton: true,
  formSelectType: "View",
  backButton: false,
  sortingMode: "server",
  hidePlatformFilter: true
};
