import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Typography,
  styled
} from "@mui/material";
import {
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES,
  LEAD_SOURCES,
  LEAD_STATUS
} from "@utils/constants";

import { Controller, UseFormReturn } from "react-hook-form";
import { SearchInput } from "@components/SearchInput";
import { ChangeEvent, useEffect, useState } from "react";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { CloseIcon } from "@components/Icons";
import { QuickAddContactForm } from "../components/QuickAddContactForm";
import { AccountDetailsForm } from "../components/AccountDetailsForm";
import { ContactDetailsSubForm } from "../components/ContactDetailsSubForm";
import { FormDatePicker } from "@components/FormDatePicker";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import {
  ModelLead,
  ModelPerson,
  useAdminCrmLeadGet,
  useAdminUserCrmGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { QuickAddAccount } from "../accounts/components/QuickAddAccount";
import { Loader } from "@components/crud/Loader";
import { LoadScript } from "@react-google-maps/api";
import { LoadingSpinner } from "@components/LoadingSpinner";
import Grid from "@mui/system/Unstable_Grid";
import { useParams } from "react-router-dom";
import { Tier } from "@pages/settings/CRMSetting";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { Check, Edit } from "@mui/icons-material";
import { OrderApproval } from "@pages/settings/CRMApprovalSettings";
import { hasPermission } from "@services/Casbin";

const libraries = ["places"] as "places"[];

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

export const OpportunityDetailsForm = ({
  disabled,
  form,
  opportunityOwner,
  setOpportunityOwner,
  selectedAccount,
  setSelectedAccount,
  selectedContact,
  setSelectedContact,
  lead,
  setLead,
  contacts,
  setContacts,
  setClosedWon,
  customCost,
  setCustomCost,
  setDiscountWithinRange,
  setOrderApprovalNeeded,
  setClosedLost,
  organizationId
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  opportunityOwner;
  setOpportunityOwner;
  selectedAccount;
  setSelectedAccount;
  selectedContact;
  setSelectedContact;
  lead;
  setLead;
  contacts;
  setContacts;
  setClosedWon?: (val: boolean) => void;
  customCost?: number | undefined;
  setCustomCost?: (val: number | undefined) => void;
  discountWithinRange?: boolean;
  setDiscountWithinRange?: (val: boolean) => void;
  setOrderApprovalNeeded?: (val: boolean) => void;
  setClosedLost?: (val: boolean) => void;
  organizationId?: string;
}) => {
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { dirtyFields }
  } = form;
  const { opportunityId } = useParams();
  const [isCustomCost, setIsCustomCost] = useState(false);
  const [opportunityOwnerOptions, setOpportunityOwnerOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [opportunityOwnerInputValue, setOpportunityOwnerInputValue] =
    useState<string>("");

  const {
    data: opportunityOwnerOptionsData,
    isLoading: isLoadingOppOwnerOptions
  } = useAdminUserCrmGet({
    ...(organizationId
      ? {
          organizationId: organizationId
        }
      : {
          type: "opportunity_owner"
        })
  });
  useEffect(() => {
    if (opportunityOwnerOptionsData?.data) {
      setOpportunityOwnerOptions(
        opportunityOwnerOptionsData.data.map((per) => ({
          label: formatFullName(per as ModelPerson),
          value: per.userId!
        }))
      );
    }
  }, [opportunityOwnerOptionsData]);

  useEffect(() => {
    if (opportunityOwner)
      setOpportunityOwnerInputValue(
        opportunityOwnerOptions.find((ow) => ow.value === opportunityOwner)
          ?.label || ""
      );
    else setOpportunityOwnerInputValue("");
  }, [opportunityOwner, opportunityOwnerOptions]);

  const [leadOptions, setLeadOptions] = useState<
    { label: string; value: string; details: ModelLead }[]
  >([]);
  const [leadNameInputValue, setLeadNameInputValue] = useState<string>("");

  const { data: leadOptionsData, isLoading: isLoadingLeadOptions } =
    organizationId ? { data: null, isLoading: false } : useAdminCrmLeadGet();

  useEffect(() => {
    if (leadOptionsData?.data) {
      setLeadOptions(
        leadOptionsData.data.leads!.map((per) => ({
          label: per.name || "",
          value: per.leadId!,
          details: per as ModelLead
        }))
      );
    }
  }, [leadOptionsData]);

  useEffect(() => {
    if (lead)
      setLeadNameInputValue(
        leadOptions.find((ow) => ow.value === lead)?.label || ""
      );
    else setLeadNameInputValue("");
  }, [lead, leadOptions]);

  useEffect(() => {
    if (selectedContact) {
      const contact = contacts.find((c) => c.value === selectedContact);
      if (contact) {
        setValue(
          "contact",
          {
            name: formatFullName(contact.details),
            personalEmail: contact.details.email,
            homeAddress: contact.details.location,
            phoneType: "MOB",
            phone: contact.details.phone,
            whatsappNumber: contact.details.whatsappNumber
          },
          {
            shouldDirty: true,
            shouldValidate: true
          }
        );
      }
    } else {
      setValue(
        "contact",
        {
          name: "",
          personalEmail: "",
          homeAddress: "",
          phoneType: "MOB",
          phone: "",
          whatsappNumber: ""
        },
        {
          shouldDirty: true,
          shouldValidate: true
        }
      );
    }
  }, [selectedContact, contacts]);

  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const [showQuickAddAccount, setShowQuickAddAccount] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("$");
  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    setCurrencySymbol(formatter.formatToParts(1)[0].value);
  }, []);

  const { data: settings, isLoading: isLoading } = organizationId
    ? { data: null, isLoading: false }
    : useConfigGet();
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [maxDiscount, setMaxDiscount] = useState<number>(0);
  const [minOrderApprovalAmount, setMinOrderApprovalAmount] =
    useState<number>(Infinity);
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);

      const priceApprovalsData = (settings.data.find(
        (item) => item.key === "crm.opportunity.pricing-tier-approval-required"
      )?.value || []) as OrderApproval[];

      const maxDiscountPreApproved = priceApprovalsData.reduce(
        (min, current) => {
          const currentAmount = parseFloat(
            current.percentage?.replace(/,/g, "") || "0"
          );
          return currentAmount < min ? currentAmount : min;
        },
        Infinity
      );
      setMaxDiscount(maxDiscountPreApproved);

      const orderApprovalsData = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];

      const minApprovalAmount = orderApprovalsData.reduce((min, current) => {
        const currentAmount = parseFloat(
          current.amount?.replace(/,/g, "") || "10000000000"
        );
        return currentAmount < min ? currentAmount : min;
      }, Infinity);
      setMinOrderApprovalAmount(minApprovalAmount);
    }
  }, [settings]);

  const [hasEditPricePermission, setHasEditPricePermission] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  useEffect(() => {
    const checkPermission = async () => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        "crm.override-pricing",
        "ON"
      );
      return res;
    };
    const fetchPermissions = async () => {
      setIsLoadingPermissions(true);
      const edit = await checkPermission();
      setHasEditPricePermission(edit);
      setIsLoadingPermissions(false);
    };
    fetchPermissions();
  }, []);
  return (
    <Loader
      isLoading={
        isLoadingLeadOptions ||
        isLoadingOppOwnerOptions ||
        isLoading ||
        isLoadingPermissions
      }
    >
      <Grid data-testid="opportunity-detail-form" container spacing={3}>
        {showQuickAddAccount && (
          <QuickAddAccount
            onClose={() => {
              setShowQuickAddAccount(false);
            }}
            onSave={(resp) => {
              setValue("account", {
                name: resp.name,
                parent: resp.parent?.name,
                category: resp.category,
                type: resp.type,
                officeAddress: resp.officeAddress,
                email: resp.email,
                website: resp.website,
                sportsOffered: [],
                noOfAthletes: "",
                aeOwner: ""
              });
              setSelectedAccount(resp.accountId);
              setShowQuickAddAccount(false);
            }}
            organizationId={organizationId}
          />
        )}

        <Modal
          open={showAddContactModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>{`Quick Add Contact for ${getValues(
                "account.name"
              )}`}</HeaderText>
              <IconButton
                onClick={() => {
                  setShowAddContactModal(false);
                  //setRefreshKey(refreshKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>

            {selectedAccount && (
              <QuickAddContactForm
                onClose={() => {
                  setShowAddContactModal(false);
                }}
                onSave={(resp) => {
                  const options = contacts;
                  setContacts([
                    ...options,
                    {
                      label: `${resp.firstName} ${resp.lastName}`,
                      value: resp.contactId,
                      isPrimary: false,
                      details: resp
                    }
                  ]);
                  setSelectedContact(resp.contactId);
                  setShowAddContactModal(false);
                }}
                accountId={selectedAccount}
              />
            )}
          </MainContainer>
        </Modal>

        <Grid xs={6}>
          <FormInput
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            label="Name"
            type="text"
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            name="type"
            control={control}
            label="Type"
            options={CRM_OPPORTUNITY_TYPES}
            disabled
            required
            rules={{ required: "Type is required" }}
          />
        </Grid>

        {!organizationId && (
          <Grid container xs={6} md={6} direction="column" spacing={0}>
            <Grid>
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  No. of Yearly Athlete Registrations
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid xs={3}>
                <FormInput
                  name="noOfAthletes"
                  control={control}
                  rules={{
                    required: "No. of Yearly Athlete Registrations is required",
                    validate: (value) =>
                      parseFloat(value) > 0 || "Enter a valid number"
                  }}
                  label=""
                  type="number"
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      "noOfAthletes",
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    );
                    if ((e as ChangeEvent<HTMLInputElement>).target.value) {
                      const cost = getCostPerAthlete(
                        parseInt(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        ),
                        pricingTier,
                        true
                      ) as {
                        cost: number;
                        setupCost: number;
                      };
                      const totalAmount = (
                        parseFloat(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        ) *
                          cost.cost +
                        (getValues().type === "NEW_BUSINESS"
                          ? cost.setupCost
                          : 0)
                      ).toFixed(2);
                      setValue("amount", totalAmount);
                      setValue(
                        "costOfAthletesPeryear",
                        `$ ${cost.cost} Per Athlete, Per Registration`
                      );
                      if (setCustomCost) setCustomCost(undefined);
                      if (setOrderApprovalNeeded)
                        setOrderApprovalNeeded(
                          parseFloat(totalAmount) > minOrderApprovalAmount
                        );
                    }
                  }}
                />
              </Grid>
              <Grid xs={8}>
                {isCustomCost ? (
                  <FormInput
                    key={1}
                    name="customCostOfAthletes"
                    control={control}
                    label={""}
                    onChange={(e) => {
                      const cost = getCostPerAthlete(
                        parseInt(getValues().noOfAthletes || "0"),
                        pricingTier,
                        true
                      ) as {
                        cost: number;
                        setupCost: number;
                      };
                      const totalAmount = (
                        parseFloat(getValues().noOfAthletes || "0") *
                          parseFloat(
                            (e as ChangeEvent<HTMLInputElement>).target.value
                          ) +
                        (getValues().type === "NEW_BUSINESS"
                          ? cost.setupCost
                          : 0)
                      ).toFixed(2);
                      setValue("amount", totalAmount);
                      setValue(
                        "costOfAthletesPeryear",
                        `$ ${
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        } Per Athlete, Per Registration`
                      );
                      const newCost = parseFloat(
                        (e as ChangeEvent<HTMLInputElement>).target.value
                      );
                      const discountOffered =
                        ((cost.cost - newCost) / cost.cost) * 100;
                      if (setDiscountWithinRange)
                        setDiscountWithinRange(discountOffered <= maxDiscount);

                      if (setCustomCost)
                        setCustomCost(
                          parseFloat(
                            (e as ChangeEvent<HTMLInputElement>).target.value
                          ) || undefined
                        );

                      if (setOrderApprovalNeeded)
                        setOrderApprovalNeeded(
                          parseFloat(totalAmount) > minOrderApprovalAmount
                        );
                    }}
                    type="text"
                    placeHolder={`Upto ${maxDiscount}% Discount Allowed`}
                  />
                ) : (
                  <FormInput
                    key={2}
                    name="costOfAthletesPeryear"
                    control={control}
                    label={""}
                    type="text"
                    disabled
                  />
                )}
              </Grid>
              {maxDiscount && hasEditPricePermission && (
                <Grid
                  xs={0.5}
                  padding={0}
                  height="fit-content"
                  alignSelf="center"
                >
                  <IconButton
                    style={{
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #E5E5E5",
                      height: "45px",
                      width: "45px"
                    }}
                    disabled={
                      disabled || !!form.formState.errors?.customCostOfAthletes
                    }
                    onClick={() => {
                      if (setIsCustomCost) {
                        if (!customCost && isCustomCost) {
                          const cost = getCostPerAthlete(
                            parseInt(getValues().noOfAthletes || "0"),
                            pricingTier,
                            true
                          ) as {
                            cost: number;
                            setupCost: number;
                          };
                          const totalCost = (
                            parseFloat(getValues().noOfAthletes || "0") *
                              cost.cost +
                            (getValues().type === "NEW_BUSINESS"
                              ? cost.setupCost
                              : 0)
                          ).toFixed(2);
                          setValue("amount", totalCost);
                          setValue(
                            "costOfAthletesPeryear",
                            `$ ${cost.cost} Per Athlete, Per Registration`
                          );
                          if (setOrderApprovalNeeded)
                            setOrderApprovalNeeded(
                              parseFloat(totalCost) > minOrderApprovalAmount
                            );
                        }
                        setIsCustomCost(!isCustomCost);
                      }
                    }}
                  >
                    {isCustomCost ? (
                      <Check height="24px" width="24px" color="primary" />
                    ) : (
                      <Edit height="24px" width="24px" color="primary" />
                    )}
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {!organizationId && (
          <Grid xs={6}>
            <FormInput
              name="amount"
              control={control}
              rules={{ required: "Amount is required" }}
              label="Estimated Yearly Amount"
              type="text"
              required={true}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        )}

        <Grid xs={6}>
          <FormSelect
            name="stage"
            control={control}
            label="Stage"
            options={CRM_OPPORTUNITY_STAGES}
            disabled={disabled}
            onChange={(e) => {
              if (setClosedWon) setClosedWon(e.target.value === "CLOSED_WON");
              if (setClosedLost)
                setClosedLost(e.target.value === "CLOSED_LOST");
            }}
            required
            rules={{ required: "Stage is required" }}
          />
        </Grid>
        <Grid xs={6}>
          <FormDatePicker
            name="closeDate"
            control={control}
            label="Close Date"
            disabled={disabled}
            required
            rules={{ required: "Close Date is required" }}
            onChange={() => {
              trigger("closeDate");
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            name="createdBy"
            control={control}
            label="Created By"
            type="text"
            disabled
            required
            rules={{ required: "Created By is required" }}
          />
        </Grid>
        <Grid xs={6}>
          <FormDatePicker
            name="createdAt"
            control={control}
            label="Date Created"
            disabled
            required
            rules={{ required: "Date Created is required" }}
          />
        </Grid>
        <Grid xs={6}>
          <Controller
            name="opportunityOwner"
            control={control}
            rules={{ required: "opportunity Owner is required" }}
            render={({ fieldState }) => (
              <FormInputContainer>
                <StyledFormLabel required>
                  <Typography display="inline" variant="formLabel">
                    {"Opportunity Owner"}
                  </Typography>
                </StyledFormLabel>
                <Autocomplete
                  disabled={disabled}
                  options={opportunityOwnerOptions || []}
                  inputValue={opportunityOwnerInputValue}
                  renderInput={(params) => {
                    return (
                      <SearchInput
                        data-testid="SEARCH_LOCATION_INPUT"
                        {...params}
                        {...fieldState}
                        value={opportunityOwnerInputValue}
                        onChange={(e) => {
                          setOpportunityOwnerInputValue(e.target.value);
                        }}
                      />
                    );
                  }}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        data-testId={`opp_owner_OPTION_${option.value}`}
                        key={option.value}
                        id={option.value}
                        selected={
                          opportunityOwner
                            ? option.value === opportunityOwner
                            : option.value === undefined
                        }
                        onClick={() => {
                          setOpportunityOwnerInputValue(option.label);
                          setOpportunityOwner(option.value);

                          setValue("opportunityOwner", option.value, {
                            shouldValidate: true
                          });
                        }}
                      >
                        <>{option.label}</>
                      </MenuItem>
                    );
                  }}
                />
              </FormInputContainer>
            )}
          />
        </Grid>
        <LoadScript
          key="address"
          googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
          libraries={libraries}
          loadingElement={<LoadingSpinner />}
        >
          <Grid xs={12}>
            <AccountDetailsForm
              disabled={disabled}
              form={form}
              hideNoOfAthletes
              setSelectedAccount={setSelectedAccount}
              setIsNewAccountSelected={setShowQuickAddAccount}
              relatesTo="OPPORTUNITY"
              relatesToId={opportunityId as string}
              account={selectedAccount}
              organizationId={organizationId}
              onAccountChange={(account) => {
                if (!dirtyFields?.name)
                  setValue(
                    "name",
                    `New Business Opportunity for ${account.account}`,
                    { shouldDirty: false }
                  );
              }}
            />
          </Grid>
          <Grid xs={12}>
            <ContactDetailsSubForm
              disabled={disabled}
              form={form}
              contactOptions={contacts}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              setShowAddContactModal={setShowAddContactModal}
              selectedAccount={selectedAccount}
              relatesTo="OPPORTUNITY"
              relatesToId={opportunityId as string}
              account={{
                accountId: selectedAccount,
                name: getValues("account.name"),
                officeAddress: getValues("account.officeAddress"),
                type: getValues("account.type")
              }}
            />
          </Grid>
        </LoadScript>
        {!organizationId && (
          <>
            <Grid xs={12} direction="column">
              <Grid>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  LEAD DETAILS
                </Typography>
              </Grid>
              <HeaderUnderLine width="100%" />
            </Grid>
            <Grid xs={6}>
              <Controller
                name="lead.name"
                control={control}
                render={({ fieldState }) => (
                  <FormInputContainer>
                    <StyledFormLabel>
                      <Typography display="inline" variant="formLabel">
                        {"Name"}
                      </Typography>
                    </StyledFormLabel>
                    <Autocomplete
                      disabled={disabled}
                      options={leadOptions || []}
                      inputValue={leadNameInputValue}
                      renderInput={(params) => {
                        return (
                          <SearchInput
                            {...params}
                            {...fieldState}
                            value={leadNameInputValue}
                            onChange={(e) => {
                              setLeadNameInputValue(e.target.value);
                            }}
                          />
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <MenuItem
                            data-testId={`lead_OPTION_${option.value}`}
                            key={option.value}
                            id={option.value}
                            selected={
                              lead
                                ? option.value === lead
                                : option.value === undefined
                            }
                            onClick={() => {
                              setLeadNameInputValue(option.label);
                              setLead(option.value);

                              setValue(
                                "lead",
                                {
                                  name: option.details.name || "",
                                  source: option.details.source?.type || "",
                                  status: option.details.status,
                                  owner: option.details?.owner?.person
                                    ? formatFullName(
                                        option.details.owner.person
                                      )
                                    : ""
                                },
                                {
                                  shouldValidate: true,
                                  shouldDirty: true
                                }
                              );
                            }}
                          >
                            <>{option.label}</>
                          </MenuItem>
                        );
                      }}
                    />
                  </FormInputContainer>
                )}
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                name="lead.source"
                control={control}
                label="Source"
                options={LEAD_SOURCES}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                name="lead.status"
                control={control}
                label="Status"
                options={LEAD_STATUS}
                disabled
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                name="lead.owner"
                type="text"
                disabled
                label="Lead Owner"
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Loader>
  );
};
