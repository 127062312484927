/* eslint-disable @typescript-eslint/no-explicit-any */
import { Toolbar } from "@components/crud/Toolbar";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { Chip, Grid, IconButton, styled, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { RenderTableView } from "@components/RenderTableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import { JIRA_CARD_STATUSES } from "@utils/constants";
import FlagIcon from "@mui/icons-material/Flag";
import {
  ModelJiraIssue,
  ModelJiraIssueStatus,
  ModelSportsgravyUser,
  useGetAdminTechGantt
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { ToolTip } from "@components/ToolTip";
import {
  format,
  parseISO,
  parse,
  startOfWeek,
  isBefore,
  subDays
} from "date-fns";
import { Container } from "@components/crud/Container";
import LinkIcon from "@assets/icons/linkIcon.svg";
import { capitalize } from "@utils/capitalize";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import { GanttChartEpicModal } from "./GanttChartEpicModal";

interface ExtendedModelUser extends ModelSportsgravyUser {
  issues: ModelJiraIssue[];
}

const DateText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const UserStatus = styled(Typography)`
  margin-left: 10px;
  border: 1px solid #000;
  border-radius: 99px;
  padding: 4px 12px;
  background-color: #fff;
  height: 35px;
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

const StyledRenderTableView = styled(RenderTableView)`
  font-size: 14px;
  .MuiDataGrid-row:hover {
    background-color: inherit !important;
  }
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-columnHeaderTitle {
    color: #666666;
    font-weight: 600;
    line-height: 17px;
  }
  .status-DONE,
  .status-DONE:hover,
  .status-REVIEW,
  .status-REVIEW:hover,
  .status-QA,
  .status-QA:hover {
    background-color: #f3f4f7 !important;
  }
  .summary-row,
  .summary-row:hover {
    background-color: #e5e5e5 !important;
  }
  .carried-forward,
  .carried-forward:hover {
    background-color: #ffeeee !important;
  }
  .rework,
  .rework:hover {
    background-color: #f9e5a4 !important;
  }
`;

export const GanttChart = () => {
  const [sprintExpanded, setSprintExpanded] = useState<
    { sprintId: string; isExpanded: boolean }[]
  >([]);
  const [sprintUserExpanded, setSprintUserExpanded] = useState<
    { sprintId: string; userId: string; isExpanded: boolean }[]
  >([]);
  const [userEpics, setUserEpics] = useState<
    | { assigneeId: string; epics: { name: string; epicId: string }[] }
    | undefined
  >(undefined);
  const datesContainerRef = useRef<HTMLDivElement>(null);
  const workLogContainerRef = useRef<HTMLDivElement>(null);

  const { data: ganttChart, isLoading: isLoading } = useGetAdminTechGantt();

  useEffect(() => {
    if (ganttChart?.data) {
      const expandedValues = [] as {
        sprintId: string;
        isExpanded: boolean;
      }[];
      const userExpandedValues = [] as {
        sprintId: string;
        userId: string;
        isExpanded: boolean;
      }[];
      (ganttChart?.data as Array<any>).map((sprint, index) => {
        expandedValues.push({
          sprintId: sprint.sprint.sprintId as string,
          isExpanded: index === 0 ? true : false
        });
        if (sprint.users)
          sprint.users.map((user) => {
            userExpandedValues.push({
              sprintId: sprint.sprint.sprintId as string,
              userId: user.userId as string,
              isExpanded: false
            });
          });
      });
      setSprintExpanded([...expandedValues]);
      setSprintUserExpanded([...userExpandedValues]);
    }
  }, [ganttChart]);

  const handleScroll = (
    containerType: "dates" | "workLog",
    e: React.UIEvent<HTMLDivElement>
  ) => {
    console.log("scroll", e);
    const { target } = e;
    const scrollLeft = (target as HTMLDivElement).scrollLeft;
    if (containerType === "dates" && workLogContainerRef.current) {
      workLogContainerRef.current.scrollLeft = scrollLeft;
    } else if (containerType === "workLog" && datesContainerRef.current) {
      datesContainerRef.current.scrollLeft = scrollLeft;
    }
  };
  const getDatesBetween = (startDate: string, endDate: string) => {
    const dates: string[] = [];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const [startYear, startMonth, startDay] = startDate
      .split("T")[0]
      .split("-")
      .map(Number);
    const [endYear, endMonth, endDay] = endDate
      .split("T")[0]
      .split("-")
      .map(Number);
    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    while (start < end) {
      const year = start.getFullYear();
      const month = (start.getMonth() + 1).toString().padStart(2, "0");
      const day = start.getDate().toString().padStart(2, "0");
      const dayOfWeek = days[start.getDay()];

      dates.push(`${year}-${month}-${day}D${dayOfWeek}`);
      start.setDate(start.getDate() + 1);
    }
    return dates;
  };

  const convertToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    if (hours === 0 && minutes === 0) {
      return "";
    }
    const formattedHours = hours > 0 ? `${hours}h` : "";
    const formattedMinutes = minutes > 0 ? ` ${minutes}m` : "";
    const formattedTime = `${formattedHours}${formattedMinutes}`;
    return formattedTime.trim();
  };

  const convertSecToHHMM = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const getTotalHours = (workLogs) => {
    let seconds = 0;

    workLogs.forEach((log) => {
      seconds += log.timeSpent;
    });
    return convertSecToHHMM(seconds);
  };

  const dateFormat = (isoDateString) => {
    if (!isoDateString) return "-";

    const [datePart] = isoDateString.split("T");
    const [year, month, day] = datePart.split("-");
    const date = parse(`${month}/${day}/${year}`, "MM/dd/yyyy", new Date());
    const formattedDate = format(date, "EEE, dd MMM");

    return formattedDate;
  };

  const calculateTotalHoursLaggingBehind = (sprint, cards) => {
    return cards
      .filter(
        (card) =>
          !card.labels.some((label) => label == "MISC" || label == "MTG")
      )
      .filter(
        (issue) =>
          (issue.status !== "QA" &&
            issue.status !== "REVIEW" &&
            issue.status !== "DONE") ||
          (issue.actualDueDate >= sprint?.startDate &&
            issue.actualDueDate <= sprint?.endDate)
      )
      .reduce((total, card) => {
        if (card.timeSpent != 0) {
          const difference = (card.originalEstimate - card.timeSpent) * -1;
          return total + difference;
        }
        return total;
      }, 0);
  };

  const calculateWorkLog = (workLogs, sprintStart, sprintEnd, timeOffs) => {
    const result: { date: string; timeLogged: string; isOnLeave: boolean }[] =
      [];
    let currentDate = sprintStart.split("T")[0];
    const currentEnd = sprintEnd.split("T")[0];

    while (new Date(currentDate) < new Date(currentEnd)) {
      const timeLogged = workLogs
        .filter((log) => {
          const logDate = log.startedAt.split("T")[0];
          return logDate === currentDate;
        })
        .reduce((total, log) => total + log.timeSpent, 0);

      const isOnLeave = timeOffs.some(
        (off) =>
          off.date.split("T")[0] === currentDate && off.isFullDay === true
      );

      result.push({
        date: currentDate,
        timeLogged: convertSecToHHMM(timeLogged),
        isOnLeave: isOnLeave
      });

      currentDate = new Date(currentDate);
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate = currentDate.toISOString().split("T")[0];
    }

    return result;
  };

  const renderChip = (value) => {
    return (
      <>
        <Chip
          style={{
            fontSize: "14px",
            padding: "4px 8px",
            fontWeight: 600
          }}
          label={
            JIRA_CARD_STATUSES.find((status) => status.value === value)?.label
          }
          sx={{
            background:
              value === "DONE"
                ? "#BBF7D0"
                : value === "REVIEW" || value == "QA"
                ? "#ECF4FC"
                : value == "IN_PROGRESS" || value == "TODAYS_PLAN"
                ? "#FBF1BC"
                : value == "ON_HOLD"
                ? "#FFEEEE"
                : "#E5E5E5",
            color:
              value === "DONE"
                ? "#166534"
                : value === "REVIEW" || value == "QA"
                ? "#093B72"
                : value == "IN_PROGRESS" || value == "TODAYS_PLAN"
                ? "#7B5B08"
                : value == "ON_HOLD"
                ? "#A11212"
                : "#666666"
          }}
        />
      </>
    );
  };

  const StyledToolTip = ({
    title,
    children,
    position
  }: {
    title: string | React.ReactNode;
    children: React.ReactNode;
    position?: "top" | "bottom" | "left" | "right";
  }) => {
    return (
      <ToolTip arrow title={title} placement={position || "top"}>
        {/* @ts-ignore */}
        {children}
      </ToolTip>
    );
  };

  const CARDS_COLUMNS: GridColDef[] = [
    {
      headerName: "",
      field: "flag",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        const { labels, status } = params.row;
        if (
          labels.some((label) => label.includes("Rework")) ||
          status === "ON_HOLD" ||
          params.row.closedSprints.find(
            (sprint) => sprint.sprintId < params.row.sprintId
          ) ||
          params.row?.linksFrom?.filter((c) => c.linkType == "Blocks").length >
            0
        ) {
          let tooltipTitle;
          const reworkLabel = labels.find((label) => label.includes("Rework"));
          const carriedForward = params.row.closedSprints.find(
            (sprint) => sprint.sprintId < params.row.sprintId
          );
          if (reworkLabel) {
            tooltipTitle = `${reworkLabel}`;
          } else if (status === "ON_HOLD") {
            tooltipTitle = "On Hold";
          } else {
            tooltipTitle = carriedForward
              ? `C/F from ${carriedForward.name}`
              : "";
          }
          const linkedFrom = params.row.linksFrom.find(
            (l) => l.linkType == "Blocks"
          );
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <StyledToolTip title={tooltipTitle} position="right">
                <FlagIcon color="error" />
              </StyledToolTip>
              {status === "ON_HOLD" && linkedFrom && (
                <StyledToolTip
                  title={`Assigned To : ${linkedFrom?.issue?.assignee?.person?.firstName} ${linkedFrom?.issue?.assignee?.person?.lastName}`}
                  position="right"
                >
                  <StyledLink
                    style={{ marginTop: "5px" }}
                    href={`https://sportsgravy.atlassian.net/browse/${linkedFrom?.issueKey}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkIcon} />
                  </StyledLink>
                </StyledToolTip>
              )}
            </div>
          );
        }
      }
    },
    {
      headerName: "Task",
      field: "summary",
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary)
          return (
            <div
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                height: "25px",
                display: "flex",
                alignItems: "center"
              }}
            >
              {params.value}
            </div>
          );
        return (
          <StyledLink
            href={`https://sportsgravy.atlassian.net/browse/${params.row.key}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.value}
          </StyledLink>
        );
      }
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 75,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        if (["STORY", "TASK"].includes(params.value)) {
          return (
            <>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "2px",
                  background: params.value === "STORY" ? "#1ABC9C" : "#4bade8",
                  marginRight: "8px"
                }}
              ></span>
              {capitalize(params.value)}
            </>
          );
        }
        if (params.value === "BUG") {
          return (
            <>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "99px",
                  background: "#E82C2C",
                  marginRight: "8px"
                }}
              ></span>
              Bug
            </>
          );
        }
      }
    },
    {
      headerName: "Task #",
      field: "key",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return (
          <StyledLink
            href={`https://sportsgravy.atlassian.net/browse/${params.value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.value}
          </StyledLink>
        );
      }
    },
    {
      headerName: "Org. Start Date",
      field: "originalStartDate",
      minWidth: 140,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return (
          <div style={{ padding: "10px 0", lineHeight: "19px" }}>
            {!params.value ? "-" : dateFormat(params.value)}
          </div>
        );
      }
    },
    {
      headerName: "Org. Due Date",
      field: "originalDueDate",
      minWidth: 140,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return (
          <div style={{ padding: "10px 0", lineHeight: "19px" }}>
            {!params.value ? "-" : dateFormat(params.value)}
          </div>
        );
      }
    },
    {
      headerName: "Act. Start Date",
      field: "actualStartDate",
      minWidth: 140,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return (
          <div style={{ padding: "10px 0", lineHeight: "19px" }}>
            {!params.value ? "-" : dateFormat(params.value)}
          </div>
        );
      }
    },
    {
      headerName: "Act. Due Date",
      field: "actualDueDate",
      minWidth: 140,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return (
          <div style={{ padding: "10px 0", lineHeight: "19px" }}>
            {!params.value ? "-" : dateFormat(params.value)}
          </div>
        );
      }
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return renderChip(params.value);
      }
    },
    {
      headerName: "Estimate",
      field: "originalEstimate",
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return `${convertToTime(params.value)}`;
      }
    },
    {
      headerName: "Actual",
      field: "timeSpent",
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        return `${convertToTime(params.value)}`;
      }
    },
    {
      headerName: "Over/Under Time",
      field: "overUnderTime",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.isSummary) return null;
        const { originalEstimate, timeSpent } = params.row;
        const diff = originalEstimate - timeSpent;
        if (timeSpent == 0) return <span>0</span>;
        if (diff > 0)
          return (
            <span style={{ color: "#1ABC9C", fontWeight: 600 }}>
              -{convertToTime(diff)}
            </span>
          );
        if (diff < 0)
          return (
            <span style={{ color: "#E82C2C", fontWeight: 600 }}>
              +{convertToTime(Math.abs(diff))}
            </span>
          );
        else return "0";
      }
    },
    {
      headerName: "Over/Under Time %",
      field: "overUnderTimePercentage",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.isSummary) return null;
        const { originalEstimate, timeSpent } = row;
        if (!originalEstimate || !timeSpent) {
          return <span>0%</span>;
        }
        const percentage = Math.round((timeSpent / originalEstimate) * 100);
        return <span>{percentage}%</span>;
      }
    }
  ];
  const getUserEpics = (issues: ModelJiraIssue[]) => {
    const uniqueEpics = new Map();
    const issueStatus: ModelJiraIssueStatus[] = [
      "READY_COMMITTED",
      "IN_PROGRESS",
      "TODAYS_PLAN",
      "ON_HOLD"
    ];
    issues
      .filter(
        (issue) =>
          issue.epic &&
          issueStatus.includes(issue.status as ModelJiraIssueStatus)
      )
      .forEach((issue) => {
        if (!uniqueEpics.has(issue?.epic?.epicId)) {
          uniqueEpics.set(issue?.epic?.epicId, {
            name: issue?.epic?.epic?.name,
            epicId: issue?.epic?.epicId
          });
        }
      });

    return Array.from(uniqueEpics.values());
  };
  return (
    <Container>
      <Toolbar title="Gantt Chart" />
      <Loader isLoading={isLoading}>
        <div style={{ height: "calc(100vh - 190px)", overflow: "auto " }}>
          {ganttChart &&
            ganttChart.data.map((sprint, index) => {
              return (
                <Grid container direction="row" key={sprint.sprint.sprintId}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      border: "0px 0px 1px 0px",
                      padding: "8px 32px 8px 32px",
                      backgroundColor: "#F3F4F7",
                      minHeight: "56px",
                      borderBottom: "1px solid #E5E5E5",
                      ...(sprintExpanded[index]?.isExpanded
                        ? {
                            position: "sticky",
                            top: 0,
                            zIndex: 1000
                          }
                        : {
                            zIndex: 999 - index
                          })
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                      xs={9.5}
                    >
                      <Grid item marginLeft="-15px">
                        <IconButton
                          onClick={() => {
                            const expanded = sprintExpanded;
                            expanded[index].isExpanded =
                              !expanded[index].isExpanded;
                            setSprintExpanded([...expanded]);
                          }}
                        >
                          {sprintExpanded[index]?.isExpanded ? (
                            <IndeterminateCheckBox color="primary" />
                          ) : (
                            <AddBox color="primary" />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item flex={1}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: "#666666",
                            fontWeight: 600
                          }}
                        >
                          {sprint.sprint.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {sprintExpanded[index]?.isExpanded && (
                    <>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{
                          backgroundColor: "#F3F4F7",
                          borderBottom: "1px solid #E5E5E5",
                          ...(sprintExpanded[index]?.isExpanded
                            ? {
                                position: "sticky",
                                top: 57,
                                zIndex: 1000
                              }
                            : {
                                zIndex: 999 - index
                              })
                        }}
                      >
                        <Grid
                          item
                          sx={{ padding: "14px", paddingLeft: "64px" }}
                          xs={5}
                          md={4}
                        >
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#666666",
                              fontWeight: 600
                            }}
                          >
                            Users
                          </Typography>
                        </Grid>
                        <Grid
                          xs={7}
                          md={8}
                          style={{ textAlign: "center" }}
                          sx={{ display: "flex", alignItems: "center" }}
                          ref={datesContainerRef}
                          onScroll={(e) => handleScroll("dates", e)}
                        >
                          <Grid>
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "17px",
                                width: "100px"
                              }}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              width: "100%",
                              maxWidth: "650px",
                              padding: "0 30px",
                              gap: "15px",
                              overflowX: "auto"
                            }}
                          >
                            {getDatesBetween(
                              sprint.sprint.startDate,
                              sprint.sprint.endDate
                            ).map((date) => {
                              const today = new Date();
                              const isToday =
                                `${today.getFullYear()}-${(today.getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}-${today
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}` === date.split("D")[0];
                              return (
                                <div key={date} style={{ minWidth: "50px" }}>
                                  <DateText
                                    sx={{
                                      color: isToday ? "#2B337A" : "#000",
                                      fontWeight: isToday ? 700 : 500
                                    }}
                                  >
                                    {date.split("D")[0].split("-")[2]}
                                  </DateText>
                                  <DateText
                                    sx={{
                                      color: isToday ? "#2B337A" : "#000",
                                      fontWeight: isToday ? 700 : 500
                                    }}
                                  >
                                    {date.split("D")[1]}
                                  </DateText>
                                </div>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                      {sprint.users &&
                        sprint.users.map(
                          (user: ExtendedModelUser, userIndex) => {
                            const laggingBehind = convertToTime(
                              calculateTotalHoursLaggingBehind(
                                sprint.sprint,
                                user.issues
                              )
                            );
                            const sprintUser = sprintUserExpanded.find(
                              (sprintUser) =>
                                sprintUser?.sprintId ===
                                  sprint.sprint?.sprintId &&
                                user.userId === sprintUser.userId
                            );
                            return (
                              <>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  style={{
                                    position: "relative",
                                    border: "0px 0px 1px 0px",
                                    padding: "8px 0px 8px 0px",
                                    backgroundColor: "#E8EEFF",
                                    minHeight: "56px",
                                    borderBottom: "1px solid #E5E5E5",
                                    flexWrap: "nowrap",
                                    ...(sprintUser?.isExpanded
                                      ? {
                                          position: "sticky",
                                          top: 115,
                                          zIndex:
                                            9999 -
                                            (sprint.users.length - userIndex)
                                        }
                                      : {})
                                  }}
                                  key={user.userId}
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    item
                                    xs={5}
                                    md={4}
                                    sx={{ paddingLeft: "50px" }}
                                  >
                                    <Grid
                                      container
                                      sx={{ alignItems: "center" }}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          setSprintUserExpanded((prev) =>
                                            prev.map((item) =>
                                              item.userId === user.userId &&
                                              item.sprintId ===
                                                sprint.sprint.sprintId
                                                ? {
                                                    ...item,
                                                    isExpanded: !item.isExpanded
                                                  }
                                                : item
                                            )
                                          );
                                        }}
                                      >
                                        {sprintUserExpanded.find(
                                          (sprintUser) =>
                                            sprintUser?.userId ===
                                              user?.userId &&
                                            sprintUser?.sprintId ===
                                              sprint?.sprint?.sprintId
                                        )?.isExpanded ? (
                                          <IndeterminateCheckBox color="primary" />
                                        ) : (
                                          <AddBox color="primary" />
                                        )}
                                      </IconButton>
                                      <Typography
                                        style={{
                                          fontSize: "18px",
                                          color: "#666666",
                                          fontWeight: 600
                                        }}
                                      >
                                        {user.person?.firstName}{" "}
                                        {user.person?.lastName}
                                      </Typography>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "15px"
                                        }}
                                      >
                                        <UserStatus
                                          style={{
                                            borderColor:
                                              laggingBehind == ""
                                                ? "#1ABC9C"
                                                : "#E82C2C"
                                          }}
                                        >
                                          {laggingBehind == "" ? (
                                            <ThumbUpIcon color="success" />
                                          ) : (
                                            <StyledToolTip
                                              title={
                                                <>
                                                  <strong>
                                                    {laggingBehind}
                                                  </strong>
                                                  <span> lagging behind</span>
                                                </>
                                              }
                                            >
                                              <ThumbDownIcon color="error" />
                                            </StyledToolTip>
                                          )}
                                        </UserStatus>
                                        {getUserEpics(user.issues).length >
                                          0 && (
                                          <div
                                            style={{
                                              height: "28px",
                                              width: "32px",
                                              padding: "2px 4px",
                                              background: "#8751DA",
                                              borderRadius: "6px",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => {
                                              setUserEpics({
                                                assigneeId:
                                                  user.jiraAccountId as string,
                                                epics: getUserEpics(user.issues)
                                              });
                                            }}
                                          >
                                            <BoltRoundedIcon
                                              style={{
                                                color: "#fff"
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    xs={7}
                                    md={8}
                                    style={{ textAlign: "center" }}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Grid>
                                      <Typography
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          lineHeight: "17px",
                                          width: "100px",
                                          borderRight: "2px solid #000"
                                        }}
                                      >
                                        {getTotalHours(user.workLogs)}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      sx={{
                                        display: "flex",
                                        width: "100%",
                                        padding: "0 30px",
                                        gap: "15px",
                                        overflowX: "auto"
                                      }}
                                    >
                                      {calculateWorkLog(
                                        user.workLogs,
                                        sprint.sprint.startDate,
                                        sprint.sprint.endDate,
                                        user.timeoff
                                      ).map((log) => {
                                        const [year, month, day] = log.date
                                          .split("-")
                                          .map(Number);
                                        const logDate = new Date(
                                          year,
                                          month - 1,
                                          day
                                        );
                                        logDate.setHours(0, 0, 0, 0);

                                        const today = new Date();
                                        today.setHours(0, 0, 0, 0);

                                        const isToday =
                                          logDate.getTime() === today.getTime();
                                        const isWeekend =
                                          logDate.getDay() === 0 ||
                                          logDate.getDay() === 6;
                                        const isFuture = logDate > today;

                                        return (
                                          <div
                                            key={
                                              sprint.sprintId +
                                              user.userId +
                                              log.date
                                            }
                                            style={{ minWidth: "50px" }}
                                          >
                                            <DateText
                                              sx={{
                                                color: log.isOnLeave
                                                  ? "#E82C2C"
                                                  : isToday
                                                  ? "#2B337A"
                                                  : isFuture && !isWeekend
                                                  ? "#808080"
                                                  : isWeekend
                                                  ? "#8A91BD"
                                                  : "#000",
                                                fontWeight: isToday ? 700 : 500
                                              }}
                                            >
                                              {log.isOnLeave
                                                ? "OFF"
                                                : log.timeLogged}
                                            </DateText>
                                          </div>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {(() => {
                                  if (sprintUser?.isExpanded) {
                                    const statusOrder = [
                                      "DONE",
                                      "QA",
                                      "REVIEW",
                                      "IN_PROGRESS",
                                      "ON_HOLD",
                                      "TODAYS_PLAN",
                                      "READY_COMMITTED"
                                    ];

                                    const filteredIssues = user.issues
                                      .filter(
                                        (issue) =>
                                          issue.status !== "READY_COMMITTED" &&
                                          issue.sprintId ===
                                            sprint.sprint.sprintId
                                      )
                                      .filter(
                                        (issue) =>
                                          (issue.status !== "QA" &&
                                            issue.status !== "REVIEW" &&
                                            issue.status !== "DONE") ||
                                          (issue?.actualDueDate &&
                                            issue?.actualDueDate >=
                                              sprint.sprint?.startDate &&
                                            issue?.actualDueDate <=
                                              sprint.sprint?.endDate)
                                      )
                                      .filter(
                                        (issue) =>
                                          !issue.labels ||
                                          !issue.labels.some(
                                            (label) =>
                                              label === "MISC" ||
                                              label === "MTG"
                                          )
                                      );
                                    const groupedByEndDate =
                                      filteredIssues.reduce((acc, issue) => {
                                        let date = (
                                          issue.actualDueDate || ""
                                        ).split("T")[0];
                                        const issueDate = parseISO(date);
                                        const today = new Date();
                                        const startOfCurrentWeek = startOfWeek(
                                          today,
                                          { weekStartsOn: 1 }
                                        );

                                        let isPreviousWeek = false;

                                        if (
                                          isBefore(
                                            issueDate,
                                            startOfCurrentWeek
                                          )
                                        ) {
                                          const startOfLastWeek = subDays(
                                            startOfCurrentWeek,
                                            7
                                          );
                                          if (
                                            isBefore(
                                              issueDate,
                                              startOfCurrentWeek
                                            ) &&
                                            isBefore(startOfLastWeek, issueDate)
                                          ) {
                                            date = startOfCurrentWeek
                                              .toISOString()
                                              .split("T")[0];
                                            isPreviousWeek = true;
                                          }
                                        }

                                        if (!acc[date]) acc[date] = [];
                                        acc[date].push({
                                          ...issue,
                                          isPreviousWeek
                                        });
                                        return acc;
                                      }, {});

                                    Object.keys(groupedByEndDate).forEach(
                                      (date) => {
                                        groupedByEndDate[date].sort((a, b) => {
                                          if (
                                            a.isPreviousWeek !==
                                            b.isPreviousWeek
                                          ) {
                                            return a.isPreviousWeek ? -1 : 1;
                                          }
                                          if (
                                            a?.actualDueDate &&
                                            b.actualDueDate
                                          ) {
                                            return a.actualDueDate.localeCompare(
                                              b.actualDueDate
                                            );
                                          }
                                        });
                                      }
                                    );

                                    const sortedGroupedIssues = Object.keys(
                                      groupedByEndDate
                                    )
                                      .sort((a, b) => new Date(a) - new Date(b))
                                      .reduce((acc, date, index) => {
                                        const sortedIssues = groupedByEndDate[
                                          date
                                        ].sort(
                                          (a, b) =>
                                            statusOrder.indexOf(a.status) -
                                            statusOrder.indexOf(b.status)
                                        );

                                        acc[date] = sortedIssues;
                                        if (date != "") {
                                          const year = date.substring(0, 4);
                                          const month = date.substring(5, 7);
                                          const day = date.substring(8, 10);
                                          const formattedDate = `${year}-${month}-${day}`;
                                          const parsedDate = new Date(
                                            `${date}T00:00:00Z`
                                          );
                                          const dayOfWeek =
                                            parsedDate.getUTCDay();
                                          if (dayOfWeek != 0) {
                                            const summaryRow = {
                                              key: `summary-${formattedDate}-${index}`,
                                              summary: `End of day ${dayOfWeek}`,
                                              isSummary: true
                                            };

                                            if (!acc[formattedDate])
                                              acc[formattedDate] = [];
                                            acc[formattedDate].push(summaryRow);
                                          }
                                        }
                                        return acc;
                                      }, {});
                                    const flattenedSortedIssues =
                                      Object.values(sortedGroupedIssues).flat();
                                    return (
                                      <StyledRenderTableView
                                        title=""
                                        hideToolbar={true}
                                        rows={flattenedSortedIssues}
                                        columns={CARDS_COLUMNS}
                                        getRowId={(row) => row.key}
                                        searchable={false}
                                        hasActionColumn={false}
                                        getRowClassName={(params) => {
                                          if (params.row.isSummary)
                                            return "summary-row";
                                          const classes = [
                                            `status-${params.row.status}`
                                          ];

                                          if (
                                            params.row.status == "ON_HOLD" ||
                                            params.row.labels.find((label) =>
                                              label.includes("Rework")
                                            )
                                          )
                                            classes.push("rework");
                                          if (
                                            params.row.closedSprints.find(
                                              (sprint) =>
                                                sprint.sprintId <
                                                params.row.sprintId
                                            )
                                          )
                                            classes.push("carried-forward");
                                          return classes.join(" ");
                                        }}
                                        hideFooter={true}
                                        pinnedColumns={{ left: [] }}
                                        getRowHeight={() => "auto"}
                                        disableColumnResize
                                      />
                                    );
                                  }
                                })()}
                              </>
                            );
                          }
                        )}
                    </>
                  )}
                </Grid>
              );
            })}
        </div>
        {userEpics && (
          <GanttChartEpicModal
            onClose={() => setUserEpics(undefined)}
            epics={userEpics}
          />
        )}
      </Loader>
    </Container>
  );
};
