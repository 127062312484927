import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { FormSelect } from "@components/FormSelect";
import {
  ACTIVITY_OUTCOME,
  ACTIVITY_RELATED_TO,
  CRM_ACTIVITY_NEXT_ACTIONS,
  CRM_CALL_DIRECTIONS
} from "@utils/constants";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import { FormLabel, InputAdornment, styled, Typography } from "@mui/material";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormTimePicker } from "@components/FormTimePicker";
import { FormInput } from "@components/FormInput";
import { Editor } from "@components/Editor";
import { SearchPartipants } from "../components/SearchParticipants";
import { useNavigate, useParams } from "react-router-dom";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { EmailActivityView } from "./components/EmailActivityView";
import formatFullName from "@utils/formatFullName";
import {
  getAdminCrmActivityActivityId,
  getAdminCrmOrgActivityActivityId,
  ModelAccountContact,
  useGetAdminCrmActivityActivityId,
  usePutAdminCrmActivityEmailMailIdStar,
  usePutAdminCrmOrgActivityEmailMailIdStar
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Search } from "@mui/icons-material";
import { capitalize } from "@utils/capitalize";
import { Loader } from "@components/crud/Loader";
import { arraysEqual } from "@utils/ArrayFunctions";
import { enqueueSnackbar } from "notistack";
import { AxiosResponse } from "axios";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const ActivityView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { activityId } = useParams();
  const { setPastCall } = useContext(CallEmailTemplateContext);
  const [selectedActivity, setSelectedActivity] = useState<
    "CALL" | "EMAIL" | "MEETING" | "TASK" | undefined
  >(undefined);
  const [descValid, setDescValid] = useState(true);
  console.log(descValid);
  const { control, getValues, setValue, reset } = useForm({
    mode: "onBlur"
  });

  useGetAdminCrmActivityActivityId(activityId!);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [emailData, setEmailData] = useState<any>();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activityData, setActivityData] = useState<AxiosResponse>();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = organizationId
        ? await getAdminCrmOrgActivityActivityId(activityId!)
        : await getAdminCrmActivityActivityId(activityId!);
      setIsLoading(false);
      setActivityData(data);
    };
    fetchData();
  }, [refreshKey]);

  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false,
    listenCallRecording: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.activities", "DELETE");
      const edit = await checkPermission("crm.activities", "EDIT");
      const view = await checkPermission("crm.activities", "VIEW");
      const listenCallRecording = await checkPermission(
        "crm.call-recording-listen",
        "ON"
      );
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view,
        listenCallRecording
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (activityData?.data) {
      const activity = activityData.data.activity;
      reset({
        relatedTo: activity.relatesTo,
        relatedField:
          activity.relatesTo === "ACCOUNT"
            ? activity.account?.name
            : activity.relatesTo === "CONTACT"
            ? formatFullName(activity.contact)
            : activity.relatesTo === "LEAD"
            ? activity.lead?.name
            : activity.relatesTo === "OPPORTUNITY"
            ? activity.opportunity?.name
            : activity.order?.name,
        date: new Date(activity.date),
        time: new Date(activity.date),
        timeZone: activity.timezone,
        description: activityData?.data?.activity?.notes,
        managerFeedback: activity.managerFeedback,
        ...(activity.type === "CALL" && {
          callParticipants: [
            ...activity.internalParicipants,
            ...activity.externalParticipants
          ].map((p) => ({
            label: formatFullName(p.contactId ? p : p.person),
            text: "",
            value: p.contactId || p.userId,
            isInternal: !!p.userId
          })),
          nextAction: activity.nextAction,
          nextActionDate: new Date(activity.nextActionDate),
          nextActionTime: new Date(activity.nextActionDate),
          callDirection: activity.call?.type,
          outcome: activity.call?.outcome
        }),
        notes: activity.notes,
        ...(activity.type === "EMAIL" && {
          to: activity.email?.to.map((t) => ({
            label: t.name,
            text: t.email
          })),
          cc: activity.email?.cc.map((t) => ({
            label: t.name,
            text: t.email
          })),
          bcc: activity.email?.bcc.map((t) => ({
            label: t.name,
            text: t.email
          })),
          ccEnabled: activity.email?.cc.length || activity.email?.bcc.length,
          subject: activity.email?.subject,
          emailContent: activity.email?.body
        }),
        ...(activity.type === "MEETING" && {
          meetingParticipants: [
            ...activity.internalParicipants,
            ...activity.externalParticipants
          ].map((p) => ({
            label: formatFullName(p.contactId ? p : p.person),
            text: "",
            value: p.contactId || p.userId,
            isInternal: !!p.userId
          }))
          //description: activity.notes
        })
      });
      setRelatedTo(activity.relatesTo);
      setSelectedActivity(activity.type);

      if (activity.type === "EMAIL") {
        setEmailData(
          activity.email
            ? {
                ...activity.email,
                emails: [
                  {
                    ...activity.email,
                    isFavourite: !!activity.email.starredBy?.length
                  },
                  ...(activity.email.replies ?? []).map((e) => ({
                    ...e,
                    isFavourite: (e.starredBy?.length || 0) > 0
                  }))
                ]
              }
            : {
                emails: []
              }
        );
      }
    }
  }, [activityData]);

  const { mutate: star } = organizationId
    ? usePutAdminCrmOrgActivityEmailMailIdStar()
    : usePutAdminCrmActivityEmailMailIdStar();
  const onStarEmail = (mailId, isStar) => {
    if (emailData && emailData.emails) {
      star(
        {
          mailId: mailId,
          data: {
            star: isStar
          }
        },
        {
          onSuccess: () => {},
          onError: () => {
            enqueueSnackbar("Failed to Star Email!", {
              variant: "error"
            });
          }
        }
      );
    }
  };

  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });

  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };
  const [relatedTo, setRelatedTo] = useState<string>();
  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Toolbar
          title="View Activity"
          backBtnClick={() => navigate("/crm/activities")}
          {...(selectedActivity != "EMAIL" &&
            permissions.edit && {
              editBtnClick: () => navigate(`/crm/activities/${activityId}/edit`)
            })}
          {...(selectedActivity === "CALL" &&
            permissions.listenCallRecording &&
            activityData?.data?.activity?.call.status == "COMPLETED" &&
            activityData.data.activity?.call?.recordingUrl && {
              addBtnClick: () => {
                const activity = activityData.data.activity;
                setPastCall({
                  activityId: activity.activityId,
                  accountId: activity.accountId,
                  accountName: activity.account?.name,
                  location: activity.account?.officeAddress,
                  phone: activity.call
                    ? activity?.call?.type === "outbound"
                      ? activity?.call.caller
                      : activity?.call.callee
                    : "",
                  callDirection: activity?.call?.type as "inbound" | "outbound",
                  call: {
                    to: activity.call
                      ? activity?.call?.type === "outbound"
                        ? activity?.call.caller
                        : activity?.call.callee
                      : "",
                    url: activity?.call?.recordingUrl
                  },
                  isConnected: activity?.accountId ? true : false,
                  contact: {
                    contactName:
                      activity?.externalParticipants?.[0]?.firstName &&
                      activity?.externalParticipants?.[0]?.lastName
                        ? activity?.externalParticipants?.[0]?.firstName +
                          " " +
                          activity?.externalParticipants?.[0]?.lastName
                        : undefined,
                    contactPhone:
                      activity?.call?.type === "outbound"
                        ? activity?.call?.caller
                        : activity?.call?.callee,
                    isPrimary:
                      activity?.externalParticipants?.[0]?.accounts &&
                      (
                        activity?.externalParticipants?.[0]
                          ?.accounts as ModelAccountContact[]
                      ).find((acc) => acc.accountId === activity?.accountId)
                        ?.isPrimary
                  }
                });
              },
              addBtnIcon: <PlayArrowRoundedIcon />,
              addBtnLabel: "Play Recording"
            })}
        />
        <Form>
          {selectedActivity != "EMAIL" && (
            <Grid container spacing={3}>
              <Grid xs={6}>
                <FormSelect
                  control={control}
                  name="relatedTo"
                  options={ACTIVITY_RELATED_TO}
                  label="Related To"
                  disabled
                />
              </Grid>
              {relatedTo && (
                <Grid xs={6}>
                  <FormInput
                    name="relatedField"
                    control={control}
                    label={`Selected ${capitalize(relatedTo)}`}
                    disabled
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              )}
              <Grid xs={3}>
                <FormDatePicker
                  disableFuture
                  name="date"
                  required
                  control={control}
                  label="Date"
                  rules={{
                    required: "Date is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid xs={3}>
                <FormTimePicker
                  name="time"
                  control={control}
                  label="Time"
                  required
                  rules={{
                    required: "Time is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid xs={6}>
                <FormSelect
                  control={control}
                  name="timeZone"
                  label="Time Zone"
                  options={timeZoneOptions}
                  required
                  rules={{
                    required: "Time Zone is required"
                  }}
                  disabled
                />
              </Grid>
              {selectedActivity === "CALL" && (
                <>
                  <Grid xs={6}>
                    <SearchPartipants
                      label="Call Participants"
                      control={control}
                      name="callParticipants"
                      required={false}
                      showAddOption={false}
                      labelField="address"
                      type="CALL"
                      onChange={() => {}}
                      disabled
                    />
                  </Grid>
                  <Grid xs={3}>
                    <FormSelect
                      label="Call Direction"
                      options={CRM_CALL_DIRECTIONS}
                      name="callDirection"
                      control={control}
                      disabled
                    />
                  </Grid>
                  <Grid xs={3}>
                    <FormSelect
                      label="Call Outcome"
                      options={ACTIVITY_OUTCOME}
                      name="outcome"
                      control={control}
                      disabled
                    />
                  </Grid>
                  <Grid xs={6}>
                    <FormSelect
                      label="Next Action"
                      options={CRM_ACTIVITY_NEXT_ACTIONS}
                      name="nextAction"
                      control={control}
                      required
                      rules={{
                        required: "Next Action is required"
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid xs={3}>
                    <FormDatePicker
                      disableFuture
                      name="nextActionDate"
                      required
                      control={control}
                      label="Date"
                      rules={{
                        required: "Next Action Date is required"
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid xs={3}>
                    <FormTimePicker
                      name="nextActionTime"
                      control={control}
                      label="Time"
                      required
                      rules={{
                        required: "Next Action Time is required"
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormInput
                      name="notes"
                      control={control}
                      label="Call Notes"
                      type="text"
                      multiline
                      rows={4}
                      disabled
                    />
                  </Grid>
                </>
              )}

              {selectedActivity == "MEETING" && (
                <>
                  <Grid xs={12}>
                    <SearchPartipants
                      label="Participants"
                      control={control}
                      name="meetingParticipants"
                      required={false}
                      showAddOption={true}
                      labelField="address"
                      type="MEETING"
                      onChange={() => {}}
                      disabled
                    />
                  </Grid>
                  <Grid xs={12} sx={{ height: "410px" }}>
                    <StyledFormLabel>
                      <Typography variant="formLabel">Meeting Notes</Typography>
                    </StyledFormLabel>
                    <Editor
                      html={
                        getValues().description ? getValues().description : null
                      }
                      onBlur={() => {
                        validateDescriptionArray(getValues().description);
                      }}
                      onValueChange={(deltaOperations, hasMedia) => {
                        if (
                          !arraysEqual(getValues().description, deltaOperations)
                        ) {
                          setValue("description", deltaOperations, {
                            shouldValidate: true,
                            shouldDirty: true
                          });
                          setValue("hasMedia", hasMedia);
                          validateDescriptionArray(deltaOperations);
                        }
                      }}
                      style={{
                        height: "350px"
                      }}
                      disabled
                    />
                  </Grid>
                </>
              )}
              {selectedActivity == "TASK" && (
                <Grid xs={12} sx={{ height: "410px" }}>
                  <StyledFormLabel>
                    <Typography variant="formLabel">Task Notes</Typography>
                  </StyledFormLabel>
                  <Editor
                    disabled
                    html={
                      getValues().description ? getValues().description : null
                    }
                    onBlur={() => {
                      validateDescriptionArray(getValues().description);
                    }}
                    onValueChange={(deltaOperations, hasMedia) => {
                      if (
                        !arraysEqual(getValues().description, deltaOperations)
                      ) {
                        setValue("description", deltaOperations, {
                          shouldValidate: true,
                          shouldDirty: true
                        });
                        setValue("hasMedia", hasMedia);
                        validateDescriptionArray(deltaOperations);
                      }
                    }}
                    style={{
                      height: "350px"
                    }}
                  />
                </Grid>
              )}
              {!organizationId && (
                <Grid xs={12}>
                  <FormInput
                    name="managerFeedback"
                    control={control}
                    label="Manager Feedback"
                    type="text"
                    multiline
                    rows={4}
                    disabled
                  />
                </Grid>
              )}
            </Grid>
          )}
          {selectedActivity == "EMAIL" && (
            <EmailActivityView
              email={emailData}
              onStar={onStarEmail}
              setRefreshKey={() => {
                setRefreshKey(refreshKey + 1);
              }}
              organizationId={organizationId}
            />
          )}
        </Form>
      </Container>
    </Loader>
  );
};
