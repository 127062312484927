/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box,
  Toolbar,
  Typography,
  Paper,
  Divider
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "./crud/Container";
import Grid from "@mui/material/Unstable_Grid2";
import LIKE from "../assets/icons/like.svg";
import LOVE from "../assets/icons/love.svg";
import CONFUSED from "../assets/icons/confused.svg";
import CELEBRATE from "../assets/icons/celebrate.svg";
import INSIGHTFUL from "../assets/icons/insightful.svg";
import NEUTRAL from "../assets/icons/neutral.svg";
import { FeedMediaGrid } from "./FeedMediaGrid";
import { Link, useParams, useSearchParams } from "react-router-dom";
import FeedIndividualMedia from "./FeedIndividualMedia";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import {
  LookupOgResponse,
  ModelPost,
  useLookupOgPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { LivestreamPost } from "@pages/feed/components/LivestreamPost";
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",
  paddingLeft: "0px !important",
  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  },
  "& .rePost": {
    padding: "30px 15px 5px 0px !important"
  }
}));
const StyledLabel = styled(Typography)(() => ({
  color: "#838383",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px",
  " &.comment-hover": {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
}));
const StyledReactions = styled(Avatar)(() => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  border: "0.5px solid #C8CDF8",
  marginLeft: "-4px"
}));
const StyledPaper = styled(Paper)(() => ({
  width: "696px",
  marginTop: "-2rem",
  display: "flex",
  flexWrap: "wrap",
  fontFamily: "Open Sans",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FFF",
  padding: "15px",
  boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.13)",
  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));

const LinkPreviewBox = styled(Box)`
  background-color: #efefef;
  border-radius: 6px;
  margin-top: 12px;
  width: 100%;
  img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    max-height: 400px;
  }
  div {
    background-color: #efefef;
    padding: 15px 15px 15px 15px;
  }
  div p:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
  }
  div p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color: #666;
  }
`;

export const ReportPostView = (props: {
  content: ModelPost;
  userId;
  rePost;
  setPrevData?;
}) => {
  const [searchParams] = useSearchParams();

  const [urls, setUrls] = useState<string[]>([]);
  const [urlMetaData, setUrlMetadata] = useState<undefined | LookupOgResponse>(
    undefined
  );

  const { type } = useParams();
  const [showMediaGrid] = useState(
    props.content?.media && props.content?.media?.length > 0
  );
  const organizationId = useRecoilValue(organizationAtom);
  const getUniqueReactions = (reactions) => {
    const uniqueReactions = [
      ...new Set(reactions.map((reaction) => reaction.type))
    ];
    return uniqueReactions;
  };
  const dateFormat = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: true
    };
    //@ts-ignore
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  const feedMediaGridDisplay = useMemo(() => {
    if (props.content) {
      return (
        showMediaGrid && (
          <Container width={"100%"} data-testid="feed=media-grid">
            <FeedMediaGrid media={props.content?.media} />
          </Container>
        )
      );
    }
    return null;
  }, [showMediaGrid, props.content]);

  const formatBody = (body, userMentions, orgMentions?) => {
    if (!body) return;
    const linkRegex = /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*/g;
    const parts = body.split(/([@#][\w\d]+|(?:http|www)\S+|\n)/g);
    return parts.map((part, index) => {
      const user = userMentions?.find(
        (user) => user.personId === part.slice(1)
      );
      const org = orgMentions.find(
        (org) => org.organizationId === part.slice(1)
      );
      if (user) {
        return (
          <Link key={index} to={`/users/${user?.personId}`} target="_blank">
            <span style={{ color: "#007AFF" }}>
              @{user.firstName} {user.middleName} {user.lastName}
            </span>
          </Link>
        );
      }
      if (org) {
        return (
          <Link
            key={index}
            to={`/organizations/${org?.organizationId}`}
            target="_blank"
          >
            <span key={index} style={{ color: "#007AFF" }}>
              @{org.name}
            </span>
          </Link>
        );
      } else if (part.match(/[#][\w\d]+/)) {
        return (
          <span key={index} style={{ color: "#007AFF" }}>
            {part}
          </span>
        );
      } else if (part.match(linkRegex)) {
        const fullURL = part.startsWith("http") ? part : `https://${part}`;
        if (urls.length == 0) {
          setUrls([fullURL]);
        }
        return (
          <a
            style={{ color: "#007AFF" }}
            key={index}
            href={fullURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else if (part === "\n") {
        return <br key={index} />;
      }

      return part;
    });
  };
  const feedReactionDisplay = useMemo(() => {
    if (
      props.content?.reactions &&
      props.content?.reactions?.length > 0 &&
      !props.rePost
    ) {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            {getUniqueReactions(props.content.reactions)
              .slice(0, 5)
              .map((reaction, index) => {
                let reactionImage;
                switch (reaction) {
                  case "LIKE":
                    reactionImage = LIKE;
                    break;
                  case "LOVE":
                    reactionImage = LOVE;
                    break;
                  case "CONFUSED":
                    reactionImage = CONFUSED;
                    break;
                  case "CELEBRATE":
                    reactionImage = CELEBRATE;
                    break;
                  case "INSIGHTFUL":
                    reactionImage = INSIGHTFUL;
                    break;
                  case "NEUTRAL":
                    reactionImage = NEUTRAL;
                    break;
                  default:
                    reactionImage = null;
                }

                return (
                  <Grid
                    sx={{
                      width: "12px",
                      height: "12px",
                      marginRight: "7px"
                    }}
                    key={index}
                  >
                    {reactionImage && (
                      <StyledReactions
                        src={reactionImage}
                        sx={{
                          padding: "3px",
                          width: 24,
                          height: 24
                        }}
                      ></StyledReactions>
                    )}
                  </Grid>
                );
              })}
          </Box>
          <Grid sx={{ marginLeft: "8px" }}>
            <StyledLabel marginLeft={1}>
              {props.content.reactions.some(
                (reaction) => reaction.userId === props.userId
              )
                ? `You${
                    props.content.reactions.length > 1
                      ? ` and ${props.content.reactions.length - 1} ${
                          props.content.reactions.length === 2
                            ? "other"
                            : "others"
                        }`
                      : ""
                  }`
                : `${props.content.reactions.length}`}
            </StyledLabel>
          </Grid>
        </>
      );
    }
    return null;
  }, [props.content.reactions]);

  const { mutate: lookup } = useLookupOgPost();
  const lookupHandler = async (url) => {
    if (url) {
      lookup(
        {
          data: {
            url: url
          }
        },
        {
          onSuccess: (data) => {
            setUrlMetadata(data.data);
          }
        }
      );
    }
  };

  useEffect(() => {
    lookupHandler(urls[0]);
  }, [urls]);

  return (
    <>
      <Container data-testid="report-post-body">
        <StyledToolbar
          className={props.rePost ? "rePost" : ""}
          key={props.content.postId}
        >
          <StyledPaper style={{ maxWidth: "100%" }}>
            <Container>
              <Grid container alignItems="center">
                <Grid>
                  {!searchParams.get("post") ? (
                    <>
                      {props.content?.createdBy?.person?.avatarId ? (
                        <Avatar
                          variant="square"
                          src={
                            props.content?.createdBy.person?.avatar?.baseUrl &&
                            props.content?.createdBy.person?.avatar?.path
                              ? props.content?.createdBy?.person?.avatar
                                  ?.baseUrl +
                                props.content?.createdBy?.person?.avatar?.path
                              : ""
                          }
                          style={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "8px"
                          }}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor:
                              "#" + props.content.createdBy.person.colorCode,
                            color: "#fff !important",
                            padding: "14px",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            textAlign: "center",
                            height: "48px",
                            width: "48px",
                            borderRadius: "8px"
                          }}
                          variant="square"
                        >
                          {`${props.content?.createdBy?.person?.firstName} ${props.content?.createdBy?.person?.lastName}`
                            .split(" ")
                            .map((name) => name[0])
                            .join("")
                            .toUpperCase()}
                        </Avatar>
                      )}
                    </>
                  ) : (
                    <>
                      {props.content?.liveStream?.organization ? (
                        <Avatar
                          variant="square"
                          src={
                            props.content?.liveStream?.organization?.avatar
                              ?.baseUrl &&
                            props.content?.liveStream?.organization?.avatar
                              ?.path
                              ? props.content?.liveStream?.organization?.avatar
                                  ?.baseUrl +
                                props.content?.liveStream?.organization?.avatar
                                  ?.path
                              : ""
                          }
                        />
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor:
                              "#" +
                              props.content?.liveStream?.organization
                                ?.colorCode,
                            color: "#fff !important"
                          }}
                          variant="square"
                        >
                          {props.content.liveStream?.organization?.abbreviation}
                        </Avatar>
                      )}
                    </>
                  )}
                </Grid>

                <Grid marginLeft={"11px"} xs container direction="column">
                  <Typography fontSize={"14px"} fontWeight={600}>
                    {!searchParams.get("post") ? (
                      <>
                        {props.content?.createdBy?.person?.firstName}{" "}
                        {props.content.createdBy?.person?.middleName}{" "}
                        {props.content.createdBy?.person?.lastName}
                      </>
                    ) : (
                      <>{props.content.liveStream?.organization?.name}</>
                    )}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={400}>
                    {dateFormat(props.content.updatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth={"85%"} margin={"15px 0 0 0 !important"}>
              <Typography
                style={{
                  textAlign: "justify",
                  fontSize: "16px",
                  fontWeight: "400",
                  overflowWrap: "break-word"
                }}
              >
                {formatBody(
                  props.content.body,
                  props.content.userMentions,
                  props.content.organizationMentions
                )}
              </Typography>
              {props.content.repostOf && (
                <button
                  style={{
                    border: "none",
                    background: "none",
                    textAlign: "left",
                    cursor:
                      (organizationId &&
                        props.content?.repostOf?.organizations &&
                        props.content?.repostOf?.organizations.some(
                          (org) => org.organizationId === organizationId
                        )) ||
                      !organizationId ||
                      (props.content.repostOf.organizations &&
                        props.content.repostOf.organizations.length == 0)
                        ? "pointer"
                        : "default"
                  }}
                  onClick={() => {
                    if (
                      (organizationId &&
                        props.content?.repostOf?.organizations &&
                        props.content?.repostOf.organizations.some(
                          (org) => org.organizationId === organizationId
                        )) ||
                      !organizationId ||
                      (props.content?.repostOf?.organizations &&
                        props.content.repostOf.organizations.length == 0)
                    ) {
                      window.open(
                        `${window.location.origin}/${
                          "feed/" + props.content?.repostOf?.postId
                        }`
                      );
                    }
                  }}
                >
                  <Container
                    style={{
                      width: "120%",
                      maxWidth: "75vw",
                      marginTop: "10px"
                    }}
                  >
                    <ReportPostView
                      rePost={true}
                      content={props.content.repostOf}
                      userId={"1"}
                    />
                  </Container>
                </button>
              )}
            </Container>
            {feedMediaGridDisplay}
            {props.content?.liveStream && (
              <LivestreamPost livestream={props.content?.liveStream} />
            )}
            {props.content.media &&
              !props.content.liveStream &&
              props.content.media.length == 0 &&
              urlMetaData && (
                <LinkPreviewBox>
                  {urlMetaData.image && <img src={urlMetaData.image} />}
                  {(urlMetaData.title || urlMetaData.url) && (
                    <Box>
                      {urlMetaData.title && (
                        <Typography>{urlMetaData.title}</Typography>
                      )}
                      {urlMetaData.url && (
                        <Typography>{urlMetaData.url}</Typography>
                      )}
                    </Box>
                  )}
                </LinkPreviewBox>
              )}
            <Container>
              {!props.rePost &&
                ((props.content?._count?.comments &&
                  props.content?._count?.comments > 0) ||
                  (props.content?._count?.shares &&
                    props.content?._count?.shares > 0) ||
                  (props.content?.reactions &&
                    props.content?.reactions?.length > 0)) && (
                  <Grid
                    container
                    marginTop={2}
                    justifyContent="space-between"
                    alignItems="center"
                    marginLeft={"5px"}
                  >
                    <Grid container alignItems="center">
                      {feedReactionDisplay}
                    </Grid>
                    <Grid container spacing={1}>
                      {props.content?._count?.comments &&
                      props.content?._count?.comments > 0 ? (
                        <Grid>
                          <StyledLabel
                            className={
                              type == "comments" ? "comment-hover" : ""
                            }
                          >
                            {props.content?._count?.comments === 1
                              ? "1 comment"
                              : `${props.content?._count?.comments} comments`}
                          </StyledLabel>
                        </Grid>
                      ) : null}
                      {props.content?._count?.shares &&
                      props.content?._count.shares > 0 ? (
                        <Grid>
                          <StyledLabel>
                            {props.content?._count.shares === 1
                              ? "1 share"
                              : `${props.content?._count.shares} shares`}
                          </StyledLabel>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
              {props.content?.liveStream && (
                <Grid
                  container
                  marginTop={2}
                  justifyContent="space-between"
                  alignItems="center"
                  marginLeft={"5px"}
                >
                  <Grid container alignItems="center">
                    {props.content?.liveStream?._count?.likes &&
                    props.content?.liveStream?._count?.likes > 0 ? (
                      <StyledLabel>
                        {props.content?.liveStream?._count?.likes === 1
                          ? "1 like"
                          : `${props.content?.liveStream?._count?.likes} likes`}
                      </StyledLabel>
                    ) : null}
                  </Grid>
                  <Grid container spacing={1}>
                    {props.content?.liveStream?._count?.comments &&
                    props.content?.liveStream?._count?.comments > 0 ? (
                      <Grid>
                        <StyledLabel>
                          {props.content?.liveStream?._count?.comments === 1
                            ? "1 comment"
                            : `${props.content?.liveStream?._count?.comments} comments`}
                        </StyledLabel>
                      </Grid>
                    ) : null}
                    {props.content?.liveStream?._count?.viewers &&
                    props.content?.liveStream?._count?.viewers > 0 ? (
                      <Grid>
                        <StyledLabel>
                          {props.content?.liveStream?._count?.viewers === 1
                            ? "1 view"
                            : `${props.content?.liveStream?._count?.viewers} views`}
                        </StyledLabel>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            </Container>
            <Container>
              {type === "comments" && !props.rePost && (
                <>
                  <Divider sx={{ marginTop: "11px !important" }} />
                  <Box
                    data-testid="report-comment-body"
                    key={props.content?.comments?.[0]?.commentId}
                    sx={{
                      borderRadius: "8px",
                      bgcolor: "#FCDFDF",
                      padding: "12px",
                      marginTop: "15px"
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid>
                        {props.content?.comments?.[0]?.user?.person
                          ?.avatarId ? (
                          <Avatar
                            sx={{
                              width: "48px",
                              height: "48px",
                              borderRadius: "8px"
                            }}
                            variant="square"
                            src={
                              props.content?.comments[0]?.user?.person?.avatar
                                ?.baseUrl &&
                              props.content?.comments[0]?.user?.person?.avatar
                                ?.path
                                ? props.content?.comments[0]?.user?.person
                                    ?.avatar?.baseUrl +
                                  props.content?.comments[0]?.user?.person
                                    ?.avatar?.path
                                : undefined
                            }
                          />
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor:
                                "#" +
                                props.content?.comments?.[0]?.user?.person
                                  ?.colorCode,
                              color: "#fff",
                              padding: "14px",
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "20px",
                              textAlign: "center",
                              height: "48px",
                              width: "48px",
                              borderRadius: "8px"
                            }}
                            variant="square"
                          >
                            {`${props.content?.comments?.[0]?.user?.person?.firstName} ${props.content?.comments?.[0]?.user?.person?.lastName}`
                              .split(" ")
                              .map((name) => name[0])
                              .join("")
                              .toUpperCase()}
                          </Avatar>
                        )}
                      </Grid>
                      <Grid marginLeft={"11px"} xs container direction="column">
                        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                          {`${props.content?.comments?.[0]?.user?.person?.firstName} ${props.content?.comments?.[0]?.user?.person?.middleName} ${props.content?.comments?.[0]?.user?.person?.lastName}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#666",
                            fontWeight: 400
                          }}
                        >
                          {dateFormat(props.content?.comments?.[0]?.createdAt)}
                        </Typography>
                      </Grid>
                      <Container style={{ background: "none" }}>
                        <Typography
                          style={{
                            width: "100%",
                            marginTop: "7px",
                            fontSize: "14px",
                            fontWeight: 400
                          }}
                        >
                          {formatBody(
                            props.content?.comments?.[0]?.comment,
                            props.content?.comments?.[0]?.mentions,
                            []
                          )}
                        </Typography>
                        <Box sx={{ width: "50%" }}>
                          {props.content?.comments?.[0]?.media &&
                            props.content?.comments[0]?.media?.length > 0 && (
                              <FeedIndividualMedia
                                src={
                                  props.content?.comments?.[0]?.media?.[0]
                                    ?.baseUrl &&
                                  props.content?.comments?.[0]?.media?.[0]?.path
                                    ? props.content?.comments?.[0]?.media?.[0]
                                        ?.baseUrl +
                                      props.content?.comments?.[0]?.media?.[0]
                                        ?.path
                                    : ""
                                }
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  marginTop: "7px",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  cursor: "pointer"
                                }}
                              />
                            )}
                        </Box>
                      </Container>
                    </Grid>
                  </Box>
                </>
              )}
            </Container>
          </StyledPaper>
        </StyledToolbar>
      </Container>
    </>
  );
};
