import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import { Box, Typography, styled, FormLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelConfig,
  ModelOrganizationAccount,
  useAdminAdvertiserBusinessCategoryGet,
  useAdminAdvertiserPost,
  useAdminSettingsGet,
  useConfigGet,
  useLookupCountryGet,
  useSponsorshipLevelGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logoPickerImage from "@assets/images/iconPicker.png";
import { FormSelect } from "@components/FormSelect";
import { FormCheckbox } from "@components/FormCheckbox";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import FormHelperText from "@mui/material/FormHelperText";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { AdvertisementCreate } from "./advertisements/AdvertismentCreate";
import {
  capitalizeEveryWord,
  capitalizeFirstCharacter
} from "@utils/capitalize";
import { Loader } from "@components/crud/Loader";
import { EMAIL_REGEX } from "@utils/validation";
import { formatCurrency } from "@utils/formatCurrency";
import { Button } from "@components/Button";
import { SearchAddAccount } from "@pages/crm/components/SearchAddAccount";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
export const AdvertiserCreate = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const currentOrg = organizations.find(
    (org) => org.organizationId == organizationId
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (!!organizationId && !currentOrg?.merchantId) {
      navigate("/advertisers");
    }
  }, [currentOrg]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [hasSecondaryContact, setHasSecondaryContact] = useState(false);
  const [tab, setTab] = useState("advertiser");
  const [iconFieldTouched, setIconFieldTouched] = useState(false);

  const { data: setting, isLoading: isSettingLoading } = organizationId
    ? useAdminSettingsGet({
        organizationId: organizationId,
        parentId: "org-live-stream"
      })
    : useConfigGet();

  const { data: levels, isLoading: levelLoading } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });

  const { data: businessCategory, isLoading: businessCategoryLoading } =
    useAdminAdvertiserBusinessCategoryGet();

  const levelsOptions = useMemo(
    () =>
      levels?.data?.sponsorshipLevels.map((level) => ({
        label: `${level.name!} - ${formatCurrency(level.amount)}`,
        value: level.sponsorshipId
      })) || [],
    [levels]
  );
  const businessCategoryOptions = useMemo(
    () =>
      businessCategory?.data.map((category) => ({
        label: (category as { label: string; value: string }).label,
        value: (category as { label: string; value: string }).value
      })) || [],
    [businessCategory]
  );
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];

  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      icon: "",
      status: "INACTIVE",
      businessName: "",
      primaryContact: true,
      isSecondaryApprovalRequired: false,
      isPrimaryApprovalRequired: false,
      contact: [] as {
        contactId: string;
        firstName: string;
        lastName: string;
        workPhone: string;
        personalPhone: string;
        personalEmail: string;
        isApprovalRequired: boolean;
      }[],
      advertisements: {
        status: "INACTIVE"
      }
    }
  });
  const [selectedAccount, setSelectedAccount] =
    useState<ModelOrganizationAccount>();
  useEffect(() => {
    if (setting?.data) {
      if (organizationId) {
        const config =
          setting?.data
            .filter(
              //@ts-ignore
              (s) =>
                //@ts-ignore
                s.settingId === "org-live-stream.advertiser-approval-required"
            )[0]
            //@ts-ignore
            .organizationSettings?.filter(
              (s) => s.organizationId === organizationId
            ).length > 0
            ? (setting?.data
                .filter(
                  //@ts-ignore
                  (s) =>
                    //@ts-ignore
                    s.settingId ===
                    "org-live-stream.advertiser-approval-required"
                )[0]
                //@ts-ignore
                .organizationSettings?.filter(
                  (s) => s.organizationId === organizationId
                )[0].value as boolean)
            : (setting?.data.filter(
                //@ts-ignore
                (s) =>
                  //@ts-ignore
                  s.settingId === "org-live-stream.advertiser-approval-required"
                //@ts-ignore
              )[0].default as boolean);
        if (config == true) form.setValue("isPrimaryApprovalRequired", config);
      } else {
        const config = setting.data?.find(
          //@ts-ignore
          (conf: ModelConfig) =>
            conf.key === "live-stream.advertiser-approval-required"
        )?.value;
        if (config == true)
          form.setValue("isPrimaryApprovalRequired", config as boolean);
      }
    }
  }, [setting]);
  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (tempFiles && tempFiles[0]) setFile(tempFiles[0]);
  };

  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });

  const [loading, setLoading] = useState(false);
  const { mutate: save, isLoading: isSaving } = useAdminAdvertiserPost();
  const onSave = async (shouldSendInvoice = false) => {
    const values = form.getValues();
    setLoading(true);
    const filesPromises = await Promise.all(
      [file].map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          return promise;
        }
      })
    );
    setLoading(false);
    if (filesPromises[0]) values["logoId"] = filesPromises[0];
    const data = {
      organizationId,
      shouldSendInvoice,
      accountId: selectedAccount!.accountId!,
      businessName: selectedAccount!.account,
      contacts: values.contact.map((c) => ({
        contactId: c.contactId,
        isApprovalRequired: c.isApprovalRequired
      })),
      logoId: values.logoId,
      status: values.status,
      sponsorshipId: values.sponsorshipId,
      businessCategoryId: values.businessCategoryId
    };
    try {
      save(
        {
          data
        },
        {
          onSuccess: () => {
            setLoading(false);
            enqueueSnackbar(`Advertiser added successfully!`, {
              variant: "success"
            });
            navigate("/advertisers");
          },
          onError: () => {
            setLoading(false);
            enqueueSnackbar(`Failed to add Advertiser !`, {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to generate image data!", {
        variant: "error"
      });
    }
  };
  const contactDetails = (type: "primaryContact" | "secondaryContact") => {
    if (setting?.data) {
      return (
        <>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`${type}_firstName`}>
              <FormInput
                name={`${type}.firstName`}
                control={form.control}
                rules={{ required: "First Name is required" }}
                label="First Name"
                type="text"
                required
                onChange={(e) => {
                  form.setValue(
                    `${type}.firstName`,
                    capitalizeFirstCharacter(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
            <Grid xs={6} data-testid={`${type}_lastName`}>
              <FormInput
                name={`${type}.lastName`}
                control={form.control}
                rules={{ required: "Last Name is required" }}
                label="Last Name"
                type="text"
                required
                onChange={(e) => {
                  form.setValue(
                    `${type}.lastName`,
                    capitalizeFirstCharacter(
                      (e as ChangeEvent<HTMLInputElement>)?.target.value
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`${type}_workPhone`}>
              <FormInput
                name={`${type}.workPhone`}
                required
                control={form.control}
                label="Work Phone"
                rules={{
                  required: "Work Phone is required",
                  validate: (value) =>
                    value === form.getValues(`${type}.personalPhone`)
                      ? "Work Phone and Personal Phone cannot be the same"
                      : undefined
                }}
                type="tel"
                {...(organizationId && {
                  country: countriesResponse?.data.find(
                    (country) => country.countryId === currentOrg?.country
                  )
                })}
              />
            </Grid>
            <Grid xs={6} data-testid={`${type}_personalPhone`}>
              <FormInput
                name={`${type}.personalPhone`}
                control={form.control}
                rules={{
                  validate: (value) =>
                    value
                      ? value === form.getValues(`${type}.workPhone`)
                        ? "Work Phone and Personal Phone cannot be the same"
                        : undefined
                      : undefined
                }}
                label="Mobile Phone"
                type="tel"
                {...(organizationId && {
                  country: countriesResponse?.data.find(
                    (country) => country.countryId === currentOrg?.country
                  )
                })}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`${type}_workEmail`}>
              <FormInput
                name={`${type}.workEmail`}
                control={form.control}
                rules={{
                  required: "Work Email is required",
                  validate: (value) =>
                    value
                      ? value === form.getValues(`${type}.personalEmail`)
                        ? "Work Email and Personal Email cannot be the same"
                        : !EMAIL_REGEX.test(value)
                        ? "Invalid Email"
                        : undefined
                      : undefined
                }}
                label="Work Email"
                type="text"
                required
              />
            </Grid>
            <Grid xs={6} data-testid={`${type}_personalEmail`}>
              <FormInput
                name={`${type}.personalEmail`}
                control={form.control}
                label="Personal Email"
                type="text"
                rules={{
                  validate: (value) =>
                    value
                      ? value === form.getValues(`${type}.workEmail`)
                        ? "Work Email and Personal Email cannot be the same"
                        : !EMAIL_REGEX.test(value)
                        ? "Invalid Email"
                        : undefined
                      : undefined
                }}
              />
            </Grid>
            <Grid xs={12} data-testid={`${type}_hasPermission`}>
              <FormCheckbox
                name={
                  type == "primaryContact"
                    ? "isPrimaryApprovalRequired"
                    : "isSecondaryApprovalRequired"
                }
                control={form.control}
                disabled={
                  type == "primaryContact" &&
                  form.getValues().isPrimaryApprovalRequired
                }
                label="Permission to approve and decline advertisements"
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };
  const crmContactDetails = (index: number) => {
    if (setting?.data) {
      return (
        <>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`contact[${index}].firstName`}>
              <FormInput
                name={`contact[${index}].firstName`}
                control={form.control}
                rules={{ required: "First Name is required" }}
                label="First Name"
                type="text"
                required
                disabled
                onChange={(e) => {
                  form.setValue(
                    `contact[${index}].firstName`,
                    capitalizeFirstCharacter(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
            <Grid xs={6} data-testid={`contact[${index}].lastName`}>
              <FormInput
                name={`contact[${index}].lastName`}
                control={form.control}
                rules={{ required: "Last Name is required" }}
                label="Last Name"
                type="text"
                required
                disabled
                onChange={(e) => {
                  form.setValue(
                    `contact[${index}].lastName`,
                    capitalizeFirstCharacter(
                      (e as ChangeEvent<HTMLInputElement>)?.target.value
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`contact[${index}].workPhone`}>
              <FormInput
                name={`contact[${index}].workPhone`}
                required
                control={form.control}
                label="Work Phone"
                disabled
                rules={{
                  required: "Work Phone is required",
                  validate: (value) =>
                    value === form.getValues(`contact[${index}].personalPhone`)
                      ? "Work Phone and Personal Phone cannot be the same"
                      : undefined
                }}
                type="tel"
                {...(organizationId && {
                  country: countriesResponse?.data.find(
                    (country) => country.countryId === currentOrg?.country
                  )
                })}
              />
            </Grid>
            <Grid xs={6} data-testid={`contact[${index}].personalPhone`}>
              <FormInput
                name={`contact[${index}].personalPhone`}
                control={form.control}
                disabled
                rules={{
                  validate: (value) =>
                    value
                      ? value === form.getValues(`contact[${index}].workPhone`)
                        ? "Work Phone and Personal Phone cannot be the same"
                        : undefined
                      : undefined
                }}
                label="Mobile Phone"
                type="tel"
                {...(organizationId && {
                  country: countriesResponse?.data.find(
                    (country) => country.countryId === currentOrg?.country
                  )
                })}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing="24px">
            <Grid xs={6} data-testid={`contact[${index}].personalEmail`}>
              <FormInput
                name={`contact[${index}].personalEmail`}
                control={form.control}
                label="Personal Email"
                type="text"
                disabled
                rules={{
                  validate: (value) =>
                    value
                      ? !EMAIL_REGEX.test(value)
                        ? "Invalid Email"
                        : undefined
                      : undefined
                }}
              />
            </Grid>
            <Grid xs={12} data-testid={`contact[${index}].hasPermission`}>
              <FormCheckbox
                name={`contact[${index}].isApprovalRequired`}
                control={form.control}
                disabled={index === 0}
                label="Permission to approve and decline advertisements"
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  if (tab === "advertiser") {
    return (
      <Loader isLoading={isSettingLoading || isFetchingCountries}>
        <Container>
          <Toolbar title="Add Advertiser" />
          <Form>
            <Grid data-testid="advertiser-add-form" container spacing={3}>
              <Grid data-testid="advertiser-icon" xs={12} md={12}>
                <Controller
                  name="icon"
                  control={form.control}
                  rules={{
                    required: "Advertiser Logo is required"
                  }}
                  render={({ field }) => (
                    <FormInputContainer>
                      <StyledFormLabel required>
                        <Typography display="inline" variant="formLabel">
                          {"Advertiser Logo"}
                        </Typography>
                      </StyledFormLabel>
                      <Grid container spacing={3} xs={12} md={12}>
                        <Grid xs={12} md={2}>
                          {file ? (
                            <MediaContianerWithDelete
                              onConfirmDelete={() => {
                                setFile(null);
                              }}
                              index={0}
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  width: "96px",
                                  height: "96px"
                                }}
                              />
                            </MediaContianerWithDelete>
                          ) : (
                            <img
                              src={logoPickerImage}
                              style={{
                                width: "96px",
                                height: "96px"
                              }}
                              onClick={() => {
                                setIconFieldTouched(true);
                                inputFileRef.current?.click();
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <input
                        type="file"
                        onChange={(e) => onImageChange(e, field)}
                        ref={inputFileRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      {iconFieldTouched && !file && (
                        <FormHelperText error>
                          Advertiser Logo is required
                        </FormHelperText>
                      )}
                    </FormInputContainer>
                  )}
                />
              </Grid>
              {!organizationId ? (
                <Grid xs={12} md={6} data-testid="advertiser-name">
                  <FormInput
                    control={form.control}
                    name="businessName"
                    type="text"
                    label="Business Name"
                    required={true}
                    rules={{
                      required: "Business Name is required"
                    }}
                    onChange={(e) => {
                      form.setValue(
                        "businessName",
                        capitalizeEveryWord(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        )
                      );
                    }}
                  />
                </Grid>
              ) : (
                <Grid xs={12} md={12}>
                  <SearchAddAccount
                    accountSelected={(selectedAccount) => {
                      setSelectedAccount(selectedAccount);
                      form.setValue(
                        "contact",
                        (
                          selectedAccount as ModelOrganizationAccount
                        ).contacts?.map((c, idx) => ({
                          contactId: c.contactId!,
                          firstName: c.contact?.firstName || "",
                          lastName: c.contact?.lastName || "",
                          workPhone: c.contact?.phone || "",
                          personalPhone: "",
                          personalEmail: c.contact?.email || "",
                          isApprovalRequired: idx === 0
                        })) || []
                      );
                    }}
                    showAddAccountOption={false}
                    label="Search and Select Organization"
                    required={true}
                    organizationId={organizationId}
                  />
                </Grid>
              )}
              <Grid
                container={!!organizationId}
                xs={organizationId ? 12 : 6}
                md={organizationId ? 12 : 6}
                direction="row"
              >
                <Grid xs={12} md={6} data-testid="advertiser-category">
                  <FormSelect
                    control={form.control}
                    name="businessCategoryId"
                    label="Business Category"
                    required={true}
                    options={businessCategoryOptions}
                    isLoading={businessCategoryLoading}
                    rules={{
                      required: "Business Category is required"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} md={6} data-testid="advertiser-level">
                <FormSelect
                  control={form.control}
                  name="sponsorshipId"
                  label="Sponsorship Level"
                  required={true}
                  options={levelsOptions}
                  isLoading={levelLoading}
                  rules={{
                    required: "Sponsorship Level is required"
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} data-testid="advertiser-status">
                <FormSelect
                  control={form.control}
                  required
                  name="status"
                  label="Status"
                  options={statusoptions}
                  disabled={true}
                  rules={{
                    required: "Status is required"
                  }}
                />
                <FormHelperText
                  style={{
                    color: "#B3B3B3",
                    fontSize: "13px",
                    marginLeft: "5px"
                  }}
                >
                  Note: Cannot be changed until active advertisement exists.
                </FormHelperText>
              </Grid>
            </Grid>

            {organizationId ? (
              <>
                {selectedAccount?.contacts?.map((c, idx) => (
                  <>
                    <Grid
                      container
                      direction="row"
                      marginTop={idx === 0 ? "10px" : "18px"}
                    >
                      <Grid xs={9} container>
                        <Grid>
                          <FormCheckbox
                            name="primaryContact"
                            control={form.control}
                            disabled
                          />
                        </Grid>
                        <Grid marginLeft={"5px"}>
                          <FormLabel
                            style={{
                              color: "#000",
                              fontWeight: 400,
                              fontSize: "12px",
                              letterSpacing: "1.2px",
                              opacity: "0.5"
                            }}
                          >
                            {" "}
                            PRIMARY CONTACT
                          </FormLabel>
                        </Grid>
                      </Grid>
                      <HeaderUnderLine />
                    </Grid>
                    <Box key={c.contactId} marginTop={"24px"}>
                      {crmContactDetails(idx)}
                    </Box>
                  </>
                ))}
              </>
            ) : (
              <>
                <Grid container direction="row" marginTop={"10px"}>
                  <Grid xs={9} container>
                    <Grid>
                      <FormCheckbox
                        name="primaryContact"
                        control={form.control}
                        disabled
                      />
                    </Grid>
                    <Grid marginLeft={"5px"}>
                      <FormLabel
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "12px",
                          letterSpacing: "1.2px",
                          opacity: "0.5"
                        }}
                      >
                        {" "}
                        PRIMARY CONTACT
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <HeaderUnderLine />
                </Grid>
                <Box marginTop={"24px"}>{contactDetails("primaryContact")}</Box>
              </>
            )}
            {!organizationId && (
              <Grid container direction="row" marginTop={"29px"}>
                <Grid xs={9} container>
                  <Grid>
                    <FormCheckbox
                      name="secondaryContact"
                      control={form.control}
                      onChange={(e) => {
                        setHasSecondaryContact(e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid marginLeft={"5px"}>
                    <FormLabel
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "1.2px",
                        opacity: "0.5"
                      }}
                    >
                      {" "}
                      SECONDARY CONTACT
                    </FormLabel>
                  </Grid>
                </Grid>
                <HeaderUnderLine />
              </Grid>
            )}
            {!organizationId && hasSecondaryContact && (
              <Box marginTop={"24px"}>{contactDetails("secondaryContact")}</Box>
            )}
          </Form>
          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            isLoading={loading || isSaving}
            saveAndNewBtnClick={
              !organizationId
                ? () => {
                    setTab("advertisement");
                  }
                : undefined
            }
            saveAndNewBtnLabel="Save & Continue"
            additionalBtns={[
              <Button
                key="save"
                variant="admin-primary"
                type="button"
                onClick={() => {
                  onSave();
                }}
                disabled={!form.formState.isValid || !file || !selectedAccount}
                isLoading={isSaving}
              >
                Save & Add Invoice
              </Button>,
              <Button
                key="save&send"
                variant="admin-primary"
                type="button"
                onClick={() => {
                  onSave(true);
                }}
                disabled={!form.formState.isValid || !file || !selectedAccount}
                isLoading={isSaving}
              >
                Save & Send Invoice
              </Button>
            ]}
            isDisabled={!form.formState.isValid || !file}
          />
          <ConfirmationDialog
            title="Are you sure you want to cancel?"
            body="All of your current changes will be lost."
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => navigate("/advertisers")}
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
          />
        </Container>
      </Loader>
    );
  }
  if (tab === "advertisement") {
    return <AdvertisementCreate form={form} logo={file} />;
  }
};
