import { Container } from "@components/crud/Container";
import { ToolTip } from "@components/ToolTip";
import { Add, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { Button } from "@components/Button";
import { SearchInput } from "@components/SearchInput";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { NoRecords } from "@components/NoRecords";
import { FormSelect } from "@components/FormSelect";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { CallMergeIcon } from "@components/Icons";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { useNavigate } from "react-router-dom";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { RenderTableView } from "@components/RenderTableView";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  ModelAccount,
  ModelAccountContact,
  ModelActivity,
  ModelMedia,
  useGetAdminCrmActivityCall,
  useGetAdminCrmActivityEmail,
  useGetAdminCrmActivityMeeting,
  useGetAdminCrmActivityTask,
  useGetAdminCrmOrgActivityCall,
  useGetAdminCrmOrgActivityEmail,
  useGetAdminCrmOrgActivityMeeting,
  useGetAdminCrmOrgActivityTask,
  usePutAdminCrmActivityEmailMailIdStar,
  usePutAdminCrmOrgActivityEmailMailIdStar
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { capitalize } from "@utils/capitalize";
import { CRM_ACTIVITY_CALL_OUTCOMES } from "@utils/constants";
import { extractTextFromDelta } from "@utils/convertDeltaToText";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { enqueueSnackbar } from "notistack";
import { EmailAttachment } from "./components/EmailAtachment";
import { AttachmentMediaViewer } from "./components/AttachmentMediaViewer";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";

const StyledLink = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const IconStyle = {
  height: "24px",
  width: "24px",
  color: "#666666"
};

const dateFormat = (iso) => {
  const date = new Date(iso);
  const formattedDate = date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short"
  });

  return formattedDate;
};

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const OneLineText = styled("div")`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const tabs = ["Call", "Email", "Meeting", "Task"];

export const Activity = ({
  showToolbar,
  hideFooter,
  hideRelatedTo,
  activities,
  onClickAdd,
  tablePadding,
  useOfflineTable,
  addLabel,
  account
}: {
  showToolbar: boolean;
  hideFooter?: boolean;
  hideRelatedTo?: boolean;
  activities?;
  onClickAdd?: () => void;
  tablePadding?;
  useOfflineTable?: boolean;
  addLabel?: string;
  account?: ModelAccount;
}) => {
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    position: "relative",
    top: 2,
    paddingBottom: 1,
    zIndex: 1,
    overflow: "visible",
    paddingLeft: tablePadding || 24,

    "& .MuiTabs-fixed.MuiTabs-scroller": {
      position: "static"
    },

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.white.main,
      height: "1px",
      marginBottom: "-1px",
      zIndex: 1,
      transition: "none"
    },

    "& .MuiTab-root": {
      textTransform: "none",
      padding: "8px 20px",

      "&.Mui-selected": {
        backgroundColor: theme.palette.white.main,
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: "1px solid transparent",
        borderRadius: "12px",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0"
      }
    }
  }));
  const organizationId = useRecoilValue(organizationAtom);
  const { setPastCall, setMergeCall } = useContext(CallEmailTemplateContext);
  const navigate = useNavigate();
  const [textSearch, setTextSearch] = useState("");
  const [activeTab, setActiveTab] = useState("Call");
  const [showMedia, setShowMedia] = useState<ModelMedia | undefined>();
  const [filter, setSelectedFilter] = useState("");
  const [rows, setRows] = useState(activities);
  const [refreshKey, setRefreshKey] = useState(0);
  const onTabChange = (event: SyntheticEvent, tab: string) => {
    setActiveTab(tab as string);
    setRefreshKey(refreshKey + 1);
    setRows(activities);
    setTextSearch("");
    setSelectedFilter("");
  };
  const userId = useRecoilValue(profileAtom)?.userId || "";
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false,
    listenRecording: false,
    joinLiveCall: false
  });
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.activities", "ADD");
      const view = await checkPermission("crm.activities", "VIEW");
      const edit = await checkPermission("crm.activities", "EDIT");
      const listenRecording = await checkPermission("crm.join-live-call", "ON");
      const joinLiveCall = await checkPermission(
        "crm.call-recording-listen",
        "ON"
      );
      const permission = {
        create,
        edit,
        view,
        joinLiveCall,
        listenRecording
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (useOfflineTable) {
      if (textSearch) {
        const filteredRows = activities.filter((row) => {
          const typeMatches = row.type === activeTab.toUpperCase();
          const searchTerm = textSearch.toLowerCase();

          const callMatches = () => {
            const searchTerms = searchTerm.toLowerCase().split(/\s+/);

            // Function to check if all search terms are present in a given string
            const matchesAllTerms = (text) => {
              return searchTerms.some(
                (term) => text?.toLowerCase().includes(term)
              );
            };

            // Check externalParticipants
            const externalMatches = row.externalParticipants?.some(
              (participant) =>
                ["firstName", "lastName"].some((field) =>
                  matchesAllTerms(participant[field])
                )
            );

            // Check internalParicipants
            const internalMatches = row.internalParicipants?.some(
              (participant) =>
                ["firstName", "lastName"].some((field) =>
                  matchesAllTerms(participant.person?.[field])
                )
            );

            return externalMatches || internalMatches;
          };
          const emailMatches = () => {
            const searchTerms = searchTerm.toLowerCase().split(/\s+/);

            const matchesAnyTerm = (text) => {
              return searchTerms.some(
                (term) => text?.toLowerCase().includes(term)
              );
            };

            const email = row.email;

            if (!email) return false;

            // Check if any of the search terms match the email fields
            const fromMatches = matchesAnyTerm(email.from?.name);
            const toMatches = email.to?.some((recipient) =>
              matchesAnyTerm(recipient.name)
            );
            const ccMatches = email.cc?.some((recipient) =>
              matchesAnyTerm(recipient.name)
            );
            const bccMatches = email.bcc?.some((recipient) =>
              matchesAnyTerm(recipient.name)
            );
            const subjectMatches = matchesAnyTerm(email.subject);

            const bodyMatches = matchesAnyTerm(
              extractTextFromDelta(email.body).join(" ")
            );

            // Return true if any of the fields match the search terms
            return (
              fromMatches ||
              toMatches ||
              ccMatches ||
              bccMatches ||
              subjectMatches ||
              bodyMatches
            );
          };

          const arrayMatchesSearchText = () => {
            const searchTerms = searchTerm.toLowerCase().split(/\s+/);

            return (extractTextFromDelta(row.notes) || []).some((str) =>
              searchTerms.every((term) => str.toLowerCase().includes(term))
            );
          };

          if (!typeMatches) return false;

          switch (activeTab) {
            case "Call":
              return callMatches();
            case "Email":
              return emailMatches();
            case "Meeting":
              return callMatches();
            default:
              return arrayMatchesSearchText();
          }
        });
        setRows(filteredRows);
      } else setRows(activities);
    } else setRefreshKey(refreshKey + 1);
  }, [textSearch, activities]);

  useEffect(() => {
    if (useOfflineTable) {
      if (filter) {
        const filteredRows = activities.filter((row) => {
          const typeMatches = row.type === activeTab.toUpperCase();
          const isMine = () => {
            return (
              row.createdById === userId ||
              row.internalParicipants.some(
                (participant) => participant.userId === userId
              )
            );
          };
          const isLiveCall = () => {
            return row.call?.status === "LIVE";
          };
          if (!typeMatches) return false;

          switch (filter) {
            case "mine":
              return isMine();
            case "live":
              return isLiveCall();
          }
        });
        setRows(filteredRows);
      } else {
        setRows(activities);
      }
    }
  }, [filter, activities]);
  const CallLiveIcon = ({
    children,
    tooltipText
  }: {
    children: React.ReactNode;
    tooltipText?: string;
  }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "5px"
        }}
      >
        <ToolTip title={tooltipText} placement="top">
          <div style={{ height: "24px" }}>{children}</div>
        </ToolTip>
        <Typography
          style={{
            color: "#E82C2C",
            fontSize: "8px",
            fontWeight: 700
          }}
        >
          LIVE
        </Typography>
      </div>
    );
  };

  const filterOptions = () => {
    if (activeTab === "Call") {
      return [
        {
          label: "All",
          value: ""
        },
        {
          label: "My Calls",
          value: "mine"
        },
        {
          label: "All Live calls",
          value: "live"
        }
      ];
    }
    if (activeTab === "Email") {
      return [
        {
          label: "All",
          value: ""
        },
        {
          label: "My Emails",
          value: "mine"
        }
      ];
    }
    if (activeTab === "Meeting") {
      return [
        {
          label: "All",
          value: ""
        },
        {
          label: "My Meetings",
          value: "mine"
        }
      ];
    }
    if (activeTab === "Task") {
      return [
        {
          label: "All",
          value: ""
        },
        {
          label: "My Tasks",
          value: "mine"
        }
      ];
    }
    return [];
  };
  const { mutate: star } = organizationId
    ? usePutAdminCrmOrgActivityEmailMailIdStar()
    : usePutAdminCrmActivityEmailMailIdStar();
  const onStarEmail = (mailId, isStar) => {
    star(
      {
        mailId: mailId,
        data: {
          star: isStar
        }
      },
      {
        onError: () => {
          enqueueSnackbar("Failed to Star Email!", {
            variant: "error"
          });
          setRefreshKey(refreshKey + 1);
        }
      }
    );
  };
  const relatedToColumn = {
    field: "relatedTo",
    headerName: "Related To",
    flex: 1,
    minWidth: 250,
    sortable: false,
    renderCell: (params) => {
      let relatesTo = "";
      let redirectLink: string | undefined = undefined;
      switch (params.row.relatesTo) {
        case "ACCOUNT":
          relatesTo = params.row.account?.name;
          if (params.row.accountId)
            redirectLink = `/crm/accounts/${params.row.accountId}`;
          break;
        case "CONTACT":
          relatesTo = formatFullName(params.row.contact);
          if (params.row.contactId)
            redirectLink = `/crm/contacts/${params.row.contactId}`;
          break;
        case "LEAD":
          relatesTo = params.row.lead?.name;
          if (params.row.leadId)
            redirectLink = `/crm/leads/${params.row.leadId}`;
          break;

        case "OPPORTUNITY":
          relatesTo = params.row.opportunity?.name;
          if (params.row.opportunityId)
            redirectLink = `/crm/opportunities/${params.row.opportunityId}`;
          break;
        case "ORDER":
          relatesTo = params.row.orderId;
          if (params.row.orderId) redirectLink = `/crm/orders/${relatesTo}`;
          break;
      }
      return (
        <TwoLineText>
          <StyledLink
            onClick={() => {
              if (redirectLink) navigate(redirectLink);
            }}
          >
            {capitalize(params.row.relatesTo)}: {relatesTo}
          </StyledLink>
        </TwoLineText>
      );
    }
  };
  const CALL_COLUMNS: GridColDef[] = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0px", display: "flex" }}>
            {permissions.view && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}`)
                }
              >
                <ToolTip title="View Activity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}/edit`)
                }
              >
                <ToolTip title="Edit Activity" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {!organizationId &&
              params.row.call?.status == "LIVE" &&
              permissions.joinLiveCall && (
                <CallLiveIcon tooltipText="Click to merge into live Call">
                  <IconButton
                    style={{ paddingBottom: "0", height: "24px" }}
                    onClick={() => {
                      let accountDetails: ModelAccount = {};
                      if (account) accountDetails = account;
                      else accountDetails = params.row.account as ModelAccount;
                      setMergeCall({
                        activityId: params.row.activityId,
                        accountId: accountDetails?.accountId,
                        accountName: accountDetails?.name,
                        location: accountDetails?.officeAddress,
                        callId: params.row?.call?.roomId,
                        phone:
                          params.row?.call?.type === "outbound"
                            ? params?.row?.call?.caller
                            : params?.row?.call?.callee,
                        callDirection: params?.row?.call?.type as
                          | "inbound"
                          | "outbound",
                        call: {
                          to:
                            params?.row?.call?.type === "outbound"
                              ? params?.row?.call?.caller
                              : params?.row?.call?.callee,
                          url: params.row.call?.recordingUrl,
                          participants: params.row.internalParicipants?.map(
                            (p) => ({
                              name: `${p.person?.firstName} ${p.person?.lastName}`,
                              role: p?.user?.roles?.[0]?.role?.name
                            })
                          )
                        },
                        isConnected: accountDetails?.accountId ? true : false,
                        contact: {
                          contactName:
                            params.row.externalParticipants?.[0]?.firstName &&
                            params.row.externalParticipants?.[0]?.lastName
                              ? params.row.externalParticipants?.[0]
                                  ?.firstName +
                                " " +
                                params.row.externalParticipants?.[0]?.lastName
                              : undefined,
                          contactPhone:
                            params?.row?.call?.type === "outbound"
                              ? params?.row?.call?.caller
                              : params?.row?.call?.callee,
                          isPrimary:
                            params.row?.externalParticipants?.[0]?.accounts &&
                            (
                              params.row?.externalParticipants?.[0]
                                ?.accounts as ModelAccountContact[]
                            ).find(
                              (acc) =>
                                acc.accountId === accountDetails?.accountId
                            )?.isPrimary
                        }
                      });
                    }}
                  >
                    <CallMergeIcon style={IconStyle} />
                  </IconButton>
                </CallLiveIcon>
              )}
            {!organizationId &&
              params.row.call?.status == "COMPLETED" &&
              params.row.call?.recordingUrl &&
              permissions.listenRecording && (
                <IconButton
                  onClick={() => {
                    let accountDetails: ModelAccount = {};
                    if (account) accountDetails = account;
                    else accountDetails = params.row.account as ModelAccount;
                    setPastCall({
                      activityId: params.row.activityId,
                      accountId: accountDetails?.accountId,
                      accountName: accountDetails?.name,
                      location: accountDetails?.officeAddress,
                      phone:
                        params.row?.call?.type === "outbound"
                          ? params?.row?.call?.caller
                          : params?.row?.call?.callee,
                      callDirection: params?.row?.call?.type as
                        | "inbound"
                        | "outbound",
                      call: {
                        to:
                          params?.row?.call?.type === "outbound"
                            ? params?.row?.call?.caller
                            : params?.row?.call?.callee,
                        url: params.row.call?.recordingUrl
                      },
                      isConnected: accountDetails?.accountId ? true : false,
                      contact: {
                        contactName:
                          params.row.externalParticipants?.[0]?.firstName &&
                          params.row.externalParticipants?.[0]?.lastName
                            ? params.row.externalParticipants?.[0]?.firstName +
                              " " +
                              params.row.externalParticipants?.[0]?.lastName
                            : undefined,
                        contactPhone:
                          params?.row?.call?.type === "outbound"
                            ? params?.row?.call?.caller
                            : params?.row?.call?.callee,
                        isPrimary:
                          params.row?.externalParticipants?.[0]?.accounts &&
                          (
                            params.row?.externalParticipants?.[0]
                              ?.accounts as ModelAccountContact[]
                          ).find(
                            (acc) => acc.accountId === accountDetails?.accountId
                          )?.isPrimary
                      }
                    });
                  }}
                >
                  <ToolTip
                    title="Click to listen to Call Recording"
                    placement="top"
                  >
                    <PlayArrowRoundedIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
              )}
          </div>
        );
      }
    },
    {
      field: "caller",
      headerName: "Caller",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const status = params.row.call?.status;
        return (
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <span>
              {formatFullName(
                params.row.call?.type === "outbound"
                  ? params.row.internalParicipants?.[0]?.person
                  : params.row.externalParticipants?.[0]
              )}
            </span>
            <span>
              {params.row.call?.type === "outbound" && status != "LIVE" && (
                <CallMadeRoundedIcon
                  style={{
                    height: "20px",
                    color: "#B3B3B3"
                  }}
                />
              )}
              {params.row.call?.type === "outbound" && status == "LIVE" && (
                <CallLiveIcon>
                  <CallMadeRoundedIcon
                    style={{
                      height: "20px",
                      color: "#1ABC9C"
                    }}
                  />
                </CallLiveIcon>
              )}
            </span>
          </div>
        );
      },
      valueGetter: (params) =>
        formatFullName(
          params.row.call?.type === "outbound"
            ? params.row.internalParicipants?.[0]?.person
            : params.row.externalParticipants?.[0]
        )
    },
    {
      field: "recipient",
      headerName: "Recipient",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const status = params.row.call?.callStatus;
        return (
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <span>
              {params.row.call?.type === "inbound"
                ? params.row.internalParicipants &&
                  params.row.internalParicipants.length > 0
                  ? (
                      params.row.internalParicipants?.map((participant) =>
                        formatFullName(participant.person)
                      ) || []
                    ).join(",")
                  : "-"
                : params.row?.externalParticipants &&
                  params.row?.externalParticipants.length > 0
                ? (
                    params.row?.externalParticipants?.map((participant) =>
                      formatFullName(participant)
                    ) || []
                  ).join(", ")
                : "-"}
            </span>
            <span>
              {params.row.call?.type === "inbound" && status != "LIVE" && (
                <CallReceivedRoundedIcon
                  style={{
                    height: "20px",
                    color: "#B3B3B3"
                  }}
                />
              )}
              {params.row.call?.type === "inbound" && status == "LIVE" && (
                <CallLiveIcon>
                  <CallReceivedRoundedIcon
                    style={{
                      height: "20px",
                      color: "#1ABC9C"
                    }}
                  />
                </CallLiveIcon>
              )}
            </span>
          </div>
        );
      },
      valueGetter: (params) => {
        const inboundParticipants =
          params.row.call?.type === "inbound"
            ? params.row.internalParicipants &&
              params.row.internalParicipants.length > 0
              ? (
                  params.row.internalParicipants?.map((participant) =>
                    formatFullName(participant.person)
                  ) || []
                ).join(", ")
              : "}"
            : null;

        const outboundParticipants =
          params.row?.externalParticipants &&
          params.row?.externalParticipants.length > 0
            ? (
                params.row?.externalParticipants?.map((participant) =>
                  formatFullName(participant)
                ) || []
              ).join(", ")
            : "}";

        const result = inboundParticipants || outboundParticipants || "}";
        return result === "}" ? "\uFFFF" : result;
      }
    },
    {
      field: "call.outcome",
      headerName: "Call Outcome",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {CRM_ACTIVITY_CALL_OUTCOMES.find(
            (c) => c.value === params.row.call?.outcome
          )?.label || "-"}
        </span>
      ),
      valueGetter: (params) => params.row?.call?.outcome
    },
    {
      field: "notes",
      headerName: "Call Notes",
      flex: 1,
      sortable: false,
      minWidth: 350,
      renderCell: (params) => (
        <TwoLineText>{params.row.notes || "-"}</TwoLineText>
      )
    },
    {
      field: "date",
      headerName: "Date / Time",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {dateFormat(params.row.createdAt)}
        </span>
      )
    },
    ...(!hideRelatedTo ? [relatedToColumn] : [])
  ];

  const EMAIL_COLUMNS: GridColDef<ModelActivity>[] = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 170,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ padding: "20px 0px", display: "flex", fontSize: "12px" }}
          >
            {permissions.view && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}`)
                }
              >
                <ToolTip title="View Activity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}/edit`)
                }
              >
                <ToolTip title="Edit Activity" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {!((params.row.email?.starredBy?.length || 0) > 0) && (
              <IconButton
                onClick={() => {
                  onStarEmail(params.row.email!.mailId, true);
                  if (params.row.email.starredBy)
                    params.row.email.starredBy = [1];
                }}
              >
                <StarOutlineRoundedIcon style={IconStyle} />
              </IconButton>
            )}
            {(params.row.email?.starredBy?.length || 0) > 0 && (
              <IconButton
                onClick={() => {
                  onStarEmail(params.row.email!.mailId, false);
                  if (params.row.email.starredBy)
                    params.row.email.starredBy = [];
                }}
              >
                <StarRateRoundedIcon style={IconStyle} />
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      field: "from",
      headerName: "Email From",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => (
        <span style={{ fontSize: "12px" }}>
          {" "}
          {row.email?.from?.name || "-"}
        </span>
      ),
      valueGetter: (params) => params.row.email?.from?.name
    },
    {
      field: "to",
      headerName: "Email To",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const recipients = [
          ...(params.row.email?.to || []),
          ...(params.row.email?.cc || []),
          ...(params.row.email?.bcc || [])
        ];
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "12px"
            }}
          >
            <span>{params.row.email?.to && params.row.email?.to[0]?.name}</span>
            <span style={{ height: "20px", width: "20px" }}>
              {recipients && recipients.length > 1 && (
                <ToolTip
                  title={
                    <div>
                      To:{" "}
                      {params.row.email?.to?.map((to) => to.name).join(", ")}
                      {params.row.email?.cc &&
                        params.row.email.cc.length > 0 && (
                          <div>
                            CC:{" "}
                            {params.row.email.cc
                              .map((cc) => cc.name)
                              .join(", ")}
                          </div>
                        )}
                      {params.row.email?.bcc &&
                        params.row.email.bcc.length > 0 && (
                          <div>
                            Bcc:{" "}
                            {params.row.email.bcc
                              .map((cc) => cc.name)
                              .join(", ")}
                          </div>
                        )}
                    </div>
                  }
                >
                  <PeopleAltRoundedIcon
                    style={{ height: "20px", width: "20px", color: "#666" }}
                  />
                </ToolTip>
              )}
            </span>
          </div>
        );
      },
      valueGetter: (params) => {
        const recipients = [
          ...(params.row.email?.to || []),
          ...(params.row.email?.cc || []),
          ...(params.row.email?.bcc || [])
        ];
        return recipients.map((recipient) => recipient.name).join(", ");
      }
    },
    {
      field: "content",
      headerName: "Email Content",
      flex: 2,
      sortable: false,
      minWidth: 500,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              fontSize: "12px"
            }}
          >
            <OneLineText>
              <span
                style={{ color: "#666666", fontWeight: 600, fontSize: "12px" }}
              >
                {params.row.email?.subject} -
              </span>
              <span
                style={{ color: "#666666", fontWeight: 400, fontSize: "12px" }}
              >
                {" "}
                {params.row.email?.body &&
                typeof params.row.email?.body === "string" &&
                params.row.email.body !== ""
                  ? params.row.email.body
                  : extractTextFromDelta(params.row.email?.body)}
              </span>
            </OneLineText>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              {params.row.email?.attachments &&
                params.row.email?.attachments.slice(0, 2).map((attachment) => (
                  <EmailAttachment
                    attachment={attachment}
                    key={attachment.mediaId}
                    onClick={() => {
                      setShowMedia(attachment);
                    }}
                  />
                ))}
            </div>
          </div>
        );
      }
    },
    {
      field: "createdAt",
      headerName: "Date / Time",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {dateFormat(params.row.updatedAt)}
        </span>
      )
    },
    ...(!hideRelatedTo ? [relatedToColumn] : [])
  ];

  const MEETING_COLUMNS: GridColDef[] = [
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ padding: "20px 0px", display: "flex", fontSize: "12px" }}
          >
            {permissions.view && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}`)
                }
              >
                <ToolTip title="View Activity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}/edit`)
                }
              >
                <ToolTip title="Edit Activity" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      field: "participants",
      headerName: "Participants",
      minWidth: 300,
      renderCell: (params) => (
        <TwoLineText>
          {params.row.internalParicipants &&
            [
              ...params.row.internalParicipants.map((p) => p.person),
              ...params.row.externalParticipants
            ]
              ?.map((participant) => formatFullName(participant))
              .join(", ")}
        </TwoLineText>
      ),
      valueGetter: (params) =>
        params.row.internalParicipants &&
        [
          ...params.row.internalParicipants.map((p) => p.person),
          ...params.row.externalParticipants
        ]
          ?.map((participant) => formatFullName(participant))
          .join(", ")
    },
    {
      field: "notes",
      headerName: "Meeting Notes",
      sortable: false,
      minWidth: 650,
      renderCell: (params) => {
        return (
          <TwoLineText>
            <>
              {extractTextFromDelta(params.row.notes || []).map((s, idx) => {
                return (
                  <div key={idx}>
                    {s}
                    <br />
                  </div>
                );
              })}
            </>
          </TwoLineText>
        );
      }
    },
    {
      field: "date",
      headerName: "Date / Time",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {dateFormat(params.row.createdAt)}
        </span>
      )
    },
    ...(!hideRelatedTo ? [relatedToColumn] : [])
  ];

  const TASK_COLUMNS = [
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ padding: "20px 0px", display: "flex", fontSize: "12px" }}
          >
            {permissions.view && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}`)
                }
              >
                <ToolTip title="View Activity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton
                onClick={() =>
                  navigate(`/crm/activities/${params.row.activityId}/edit`)
                }
              >
                <ToolTip title="Edit Activity" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      field: "notes",
      headerName: "Task Notes",
      sortable: false,
      minWidth: 750,
      flex: 1,
      renderCell: (params) => {
        return (
          <TwoLineText>
            <>
              {extractTextFromDelta(params.row.notes || []).map((s, idx) => {
                return (
                  <div key={idx}>
                    {s}
                    <br />
                  </div>
                );
              })}
            </>
          </TwoLineText>
        );
      }
    },
    {
      field: "createdAt",
      headerName: "Date / Time",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span style={{ fontSize: "12px" }}>
          {" "}
          {dateFormat(params.row.createdAt)}
        </span>
      )
    },
    ...(!hideRelatedTo ? [relatedToColumn] : [])
  ];

  const getCallActivities = (params, options) => {
    if (textSearch) params.textSearch = textSearch;
    else delete params["textSearch"];
    if (filter) params.filter = filter;
    else delete params["filter"];
    if (organizationId) params.organizationId = organizationId;
    const query = organizationId
      ? useGetAdminCrmOrgActivityCall(params, options)
      : useGetAdminCrmActivityCall(params, options);
    const data = query?.data?.data.activities || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  };
  const getEmailActivities = (params, options) => {
    if (textSearch) params.textSearch = textSearch;
    else delete params["textSearch"];
    if (filter) params.filter = filter;
    else delete params["filter"];
    if (organizationId) params.organizationId = organizationId;
    const query = organizationId
      ? useGetAdminCrmOrgActivityEmail(params, options)
      : useGetAdminCrmActivityEmail(params, options);
    const data = query?.data?.data.activities || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  };
  const getMeetingActivities = (params, options) => {
    if (textSearch) params.textSearch = textSearch;
    else delete params["textSearch"];
    if (filter) params.filter = filter;
    else delete params["filter"];
    if (organizationId) params.organizationId = organizationId;
    const query = organizationId
      ? useGetAdminCrmOrgActivityMeeting(params, options)
      : useGetAdminCrmActivityMeeting(params, options);
    const data = query?.data?.data.activities || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  };
  const getTaskActivities = (params, options) => {
    if (textSearch) params.textSearch = textSearch;
    else delete params["textSearch"];
    if (filter) params.filter = filter;
    else delete params["filter"];
    if (organizationId) params.organizationId = organizationId;
    const query = organizationId
      ? useGetAdminCrmOrgActivityTask(params, options)
      : useGetAdminCrmActivityTask(params, options);
    const data = query?.data?.data.activities || [];
    const numRows = query?.data?.data?.numRows || 0;
    return { ...query, data, numRows };
  };
  return (
    <Container>
      {showToolbar && (
        <StyledBox>
          <div>
            <Typography
              style={{
                color: "#1E293B",
                fontWeight: 300,
                fontSize: "32px"
              }}
            >
              Activity
            </Typography>
            {permissions.create && (
              <Button
                variant="admin-primary"
                startIcon={<Add style={{ height: "27px", width: "27px" }} />}
                onClick={onClickAdd}
              >
                Add
              </Button>
            )}
          </div>
          <SearchInput
            required={false}
            placeholder="Search"
            onChange={(e) => {
              setTextSearch(e.target.value);
            }}
            //@ts-ignore
            value={textSearch}
          />
        </StyledBox>
      )}
      <Grid container>
        <Grid xs={9}>
          <StyledTabs value={activeTab} onChange={onTabChange}>
            {tabs.map((tab) => (
              <Tab
                data-testid={"TAB_BUTTON_" + tab}
                key={tab}
                label={
                  <Grid
                    container
                    sx={{
                      flex: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px"
                    }}
                  >
                    <Typography variant="tableHeader">{tab}</Typography>
                  </Grid>
                }
                value={tab}
              />
            ))}
          </StyledTabs>
        </Grid>
        <Grid
          xs={3}
          style={{
            paddingRight: "24px",
            display: "flex",
            alignItems: "center",
            gap: "8px"
          }}
        >
          <Typography style={{ fontSize: "18px" }}>View</Typography>
          <FormSelect
            name="filter"
            options={filterOptions()}
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              setRefreshKey(refreshKey + 1);
            }}
            value={filter}
          />
        </Grid>
      </Grid>
      <Loader isLoading={isLoadingPermissions}>
        {useOfflineTable ? (
          <RenderTableView
            // adding this key prop because when columns change based on state and the width does not match, it leads to infinite loops
            key={activeTab}
            style={{
              paddingLeft: tablePadding || 24
            }}
            rows={rows.filter((a) => a.type == activeTab.toUpperCase())}
            title=""
            hideToolbar
            columns={
              activeTab === "Call"
                ? CALL_COLUMNS
                : activeTab === "Email"
                ? EMAIL_COLUMNS
                : activeTab === "Meeting"
                ? MEETING_COLUMNS
                : TASK_COLUMNS
            }
            getRowId={(row) => row.activityId}
            hasActionColumn={false}
            getRowHeight={() => "auto"}
            hideFooter={!!hideFooter}
            hideFooterPagination
            pinnedColumns={{
              left: [
                "action",
                activeTab === "Call" || activeTab === "Email"
                  ? "from"
                  : activeTab === "Meeting"
                  ? "participants"
                  : "notes"
              ]
            }}
            noRecordsFoundElemet={
              <NoRecords
                title="The list is empty"
                description={
                  addLabel ? addLabel : "Add activity related to this account"
                }
                buttonClick={onClickAdd}
                buttonText="Add Activity"
                buttonIcon={<Add />}
              />
            }
          />
        ) : (
          <TableView
            style={{ marginTop: "-50px" }}
            title=""
            useGet={
              activeTab === "Call"
                ? getCallActivities
                : activeTab === "Email"
                ? getEmailActivities
                : activeTab === "Meeting"
                ? getMeetingActivities
                : getTaskActivities
            }
            columns={
              activeTab === "Call"
                ? CALL_COLUMNS
                : activeTab === "Email"
                ? EMAIL_COLUMNS
                : activeTab === "Meeting"
                ? MEETING_COLUMNS
                : TASK_COLUMNS
            }
            searchable={false}
            getRowId={(row) => row.activityId}
            hideFilter
            isDeleteDisabled={() => true}
            refreshKey={refreshKey}
            getRowHeight={() => "auto"}
            hasActionColumn={false}
            pinnedColumns={{
              left: [
                "action",
                activeTab === "Call" || activeTab === "Email"
                  ? "from"
                  : activeTab === "Meeting"
                  ? "participants"
                  : "notes"
              ]
            }}
          />
        )}
      </Loader>
      {!!showMedia && (
        <AttachmentMediaViewer
          media={showMedia}
          onClose={() => {
            setShowMedia(undefined);
          }}
        />
      )}
    </Container>
  );
};

Activity.defaultProps = {
  showToolbar: true
};
