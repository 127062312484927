import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Grid } from "@mui/material";
import { organizationAtom } from "@recoil/auth";
import { getCountries } from "@services/Network";
import {
  ContactCreateInput,
  ModelCountry,
  useAdminCrmContactPost,
  useAdminCrmOrgContactPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { cleanObject } from "@utils/cleanObject";
import { SUFFIX_OPTIONS } from "@utils/constants";
import { EMAIL_REGEX } from "@utils/validation";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";

export const QuickAddContactForm = ({
  onClose,
  onSave,
  accountId
}: {
  onClose: () => void;
  onSave;
  accountId: string;
}) => {
  const form = useForm({
    mode: "onBlur"
  });
  const {
    control,
    setValue,
    getValues,
    formState: { isValid, isDirty }
  } = form;

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const organizationId = useRecoilValue(organizationAtom);

  const countries = getCountries();
  const [country, setCountry] = useState<ModelCountry | undefined>(undefined);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name || ""} ${
      route?.long_name || ""
    }`;
    const country = addressComponents.find((c) => c.types.includes("country"));

    if (
      countries &&
      countries.data &&
      countries.data.find((c) => c.countryId == country?.short_name)
    ) {
      setCountry(
        countries.data.find((c) => c.countryId == country?.short_name)
      );
    }

    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    let city = addressComponents.find((c) => c.types.includes("locality"));
    if (!city)
      city = addressComponents.find(
        (c) =>
          c.types.includes("sublocality") || c.types.includes("postal_town")
      );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue("addressComponents", {
      postalCode: zip?.short_name || zip?.long_name,
      locality: city?.short_name || city?.long_name,
      province: state.short_name,
      country: country.short_name,
      lines: [address1]
    });
    setValue("location", place.formatted_address);
  };

  useEffect(() => {
    setRefreshKey((prev) => prev + 1);
  }, []);

  const { mutate: save, isLoading: isSaving } = organizationId
    ? useAdminCrmOrgContactPost()
    : useAdminCrmContactPost();
  const onQuickSave = () => {
    const data = {
      firstName: getValues().firstName || "",
      middleName: getValues().middleName || "",
      lastName: getValues().lastName,
      suffix: getValues().suffix || "",
      location: getValues().location,
      phone: getValues().mobilePhone || "",
      whatsappNumber: getValues().whatsappNumber || "",
      email: getValues().personalEmail || "",
      ...(organizationId && {
        organizationId: organizationId
      }),
      accounts: [
        {
          accountId: accountId,
          jobTitle: "",
          isPrimary: false,
          workEmail: getValues().personalEmail,
          workPhone: "",
          workPhoneType: ""
        }
      ],
      links: []
    };
    save(
      {
        data: cleanObject(data) as ContactCreateInput
      },
      {
        onSuccess: (resp) => {
          enqueueSnackbar("Contact Created successfully!", {
            variant: "success"
          });

          onSave(resp.data);
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Contact!", {
            variant: "error"
          });
        }
      }
    );
  };

  return (
    <Container data-testid="addContactForm">
      <Form>
        <Grid container direction="column" spacing="25px">
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={3}>
              <FormInput
                name="firstName"
                control={control}
                rules={{ required: "First Name is required" }}
                label="First Name"
                type="text"
                required={true}
                onChange={(e) => {
                  setValue(
                    "firstName",
                    capitalize(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormInput
                name="middleName"
                control={control}
                label="Middle Name"
                type="text"
                onChange={(e) => {
                  setValue(
                    "middleName",
                    capitalize(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormInput
                name="lastName"
                control={control}
                rules={{ required: "Last Name is required" }}
                label="Last Name"
                type="text"
                required={true}
                onChange={(e) => {
                  setValue(
                    "lastName",
                    capitalize(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelect
                name="suffix"
                control={control}
                label="Suffix"
                options={SUFFIX_OPTIONS}
              />
            </Grid>
          </Grid>
          <Grid item key={refreshKey}>
            <AddressAutocomplete
              name="location"
              control={control}
              rules={{}}
              required={false}
              selectSuggestion={selectAddressSuggestion}
              label="Home Location"
              trigger={form.trigger}
            />
          </Grid>

          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormInput
                name="mobilePhone"
                control={control}
                rules={{ required: "Mobile Phone is required" }}
                label="Mobile Phone"
                type="tel"
                required={true}
                country={country}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                name="whatsappNumber"
                control={control}
                label="Whatsapp Number"
                type="tel"
                country={country}
              />
            </Grid>
          </Grid>

          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormInput
                name="personalEmail"
                control={control}
                label="Email"
                type="text"
                required
                rules={{
                  required: "Email is required",
                  validate: (value) => {
                    if (!EMAIL_REGEX.test(value) && value && value != "") {
                      return "Invalid Email";
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onQuickSave();
        }}
        isDisabled={!isDirty || !isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => onClose()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
