import { Container } from "@components/crud/Container";
import { RenderTableView } from "@components/RenderTableView";
import { ToolTip } from "@components/ToolTip";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { Box, Grid, IconButton, styled, Typography } from "@mui/material";
import { Button } from "@components/Button";
import { SearchInput } from "@components/SearchInput";
import { useContext, useEffect, useState } from "react";
import { NoRecords } from "@components/NoRecords";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/Language";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { formatCurrency } from "@utils/formatCurrency";
import { FormSelect } from "@components/FormSelect";
import {
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import {
  ModelAccount,
  ModelOpportunity,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { createCRMActivity } from "@services/Network";
import { enqueueSnackbar } from "notistack";
import { Loader } from "@components/crud/Loader";
import { websiteClick } from "@utils/openWebsite";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const dateFormat = (isoString: string): string => {
  const date = new Date(isoString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const AccountOpportunity = ({
  account,
  opportunities,
  onClickAdd,
  organizationId
}: {
  account: ModelAccount;
  opportunities;
  onClickAdd: () => void;
  organizationId?: string;
}) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const {
    setCallerDetails,
    connectToCall,
    setCallAccepted,
    callInstance,
    setEmailTo
  } = useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();
  const [textSearch, setTextSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [rows, setRows] = useState(opportunities);
  const userId = useRecoilValue(profileAtom)?.userId || "";
  useEffect(() => {
    const filteredRows = opportunities.filter((row) => {
      return (
        row.name && row.name.toLowerCase().includes(textSearch.toLowerCase())
      );
    });
    setRows(filteredRows);
  }, [textSearch, opportunities]);

  useEffect(() => {
    if (filter === "mine") {
      const filteredRows = opportunities.filter((row) => {
        return userId && row.ownerId === userId;
      });
      setRows(filteredRows);
    } else setRows(opportunities);
  }, [filter, opportunities]);

  const navigate = useNavigate();
  const OPPORTUNITY_COLUMNS: GridColDef<ModelOpportunity>[] = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 270,
      sortable: false,
      renderHeader: () => {
        return <div style={{ padding: "20px 20px" }}>Action</div>;
      },
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 20px", display: "flex" }}>
            <IconButton
              onClick={() => {
                navigate(`/crm/opportunities/${params.row.opportunityId}`);
              }}
            >
              <ToolTip
                title="Click for Opportunity Dashboard"
                placement="top-start"
              >
                <SpaceDashboardIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(
                  `/crm/opportunities/${params.row.opportunityId}?tab=Opportunity Details`
                );
              }}
            >
              <ToolTip title="View Opportunity" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`/crm/opportunities/${params.row.opportunityId}/edit`);
              }}
            >
              <ToolTip title="Edit Opportunity" placement="top">
                <Edit style={IconStyle} />
              </ToolTip>
            </IconButton>
            {!organizationId && (
              <>
                <Loader isLoading={mutation.isLoading}>
                  <IconButton
                    disabled={!params.row.contact?.phone}
                    onClick={() => {
                      const contact = params.row.contact;
                      const isPrimary = account.contacts?.find(
                        (c) => c.contactId === contact?.contactId
                      )?.isPrimary;
                      if (callInstance) {
                        enqueueSnackbar("You are already on a call!", {
                          variant: "error"
                        });
                        return;
                      }
                      mutation.mutate(
                        {
                          data: {
                            accountId: account.accountId,
                            opportunityId: params.row.opportunityId,
                            type: "CALL",
                            relatesTo: "OPPORTUNITY",
                            date: new Date(),
                            timezone: dayjs.tz.guess(),
                            direction: "outbound",
                            status: "LIVE",
                            outcome: "CONNECTED_WITH_CONTACT",
                            internalParticipants: [user?.userId as string],
                            externalParticipants: [
                              {
                                contactId: contact?.contactId,
                                phone: contact?.phone || ""
                              }
                            ]
                          }
                        },
                        {
                          onSuccess: (data) => {
                            setCallerDetails({
                              activityId: data?.data?.activity?.activityId,
                              contactId: contact?.contactId,
                              accountId: account.accountId,
                              accountName: account.name,
                              callDirection: "outbound",
                              location: account.officeAddress,
                              phone: contact?.phone,
                              isConnected: account.accountId ? true : false,
                              contact: {
                                contactName:
                                  contact?.firstName + " " + contact?.lastName,
                                contactPhone: contact?.phone,
                                isPrimary: isPrimary
                              }
                            });
                            connectToCall(
                              contact?.phone as string,
                              data?.data?.activity?.activityId
                            );
                            setCallAccepted(true);
                          }
                        }
                      );
                    }}
                  >
                    <ToolTip title="Click to Call" placement="top-start">
                      <CallIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                </Loader>
                <IconButton
                  onClick={() =>
                    setEmailTo({
                      to: params.row.contact?.email,
                      name:
                        params.row.contact?.firstName +
                        " " +
                        params.row.contact?.lastName,
                      accountId: account.accountId,
                      relatesTo: "ACCOUNT"
                    })
                  }
                  disabled={!params.row.contact?.email}
                >
                  <ToolTip title="Click to Send Email" placement="top-start">
                    <EmailIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
              </>
            )}
            <IconButton
              onClick={() => websiteClick(account.website)}
              disabled={!account.website}
            >
              <ToolTip title="Click to Visit Website" placement="top">
                <WebsiteIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => <TwoLineText>{params.row.name}</TwoLineText>
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ value }) =>
        CRM_OPPORTUNITY_TYPES.find((o) => o.value === value)?.label
    },
    {
      field: "closeDate",
      headerName: "Close Date",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => dateFormat(params.value)
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_STAGES.find((o) => o.value === row.stage)?.label
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => formatCurrency(params.value)
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => formatFullName(row?.contact as ModelPerson)
    },
    {
      field: "opportunityOwner",
      headerName: "Opportunity Owner",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) =>
        formatFullName(row?.owner?.person as ModelPerson)
    }
  ];

  return (
    <Container>
      <StyledBox>
        <div>
          <Typography
            style={{
              color: "#1E293B",
              fontWeight: 300,
              fontSize: "32px"
            }}
          >
            Opportunities
          </Typography>
          <Button
            variant="admin-primary"
            startIcon={<Add style={{ height: "27px", width: "27px" }} />}
            onClick={onClickAdd}
          >
            Add
          </Button>
        </div>
        <Grid container>
          <Grid xs={4} gap="8px">
            <Typography>View</Typography>
            <FormSelect
              sx={{ width: "95%", marginRight: "24px" }}
              name=""
              required={false}
              options={[
                {
                  label: "All",
                  value: "all"
                },
                {
                  label: "My Opportunities",
                  value: "mine"
                }
              ]}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid xs={8}>
            <SearchInput
              required={false}
              placeholder="Search"
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </Grid>
        </Grid>
      </StyledBox>
      <RenderTableView
        title=""
        hideToolbar
        rows={rows}
        columns={OPPORTUNITY_COLUMNS}
        getRowId={(row) => row.opportunityId}
        hasActionColumn={false}
        hideFooter
        sortingMode="client"
        getRowHeight={() => "auto"}
        noRecordsFoundElemet={
          <NoRecords
            title="The list is empty"
            description="Add opportunity related to this account"
            buttonClick={onClickAdd}
            buttonText="Add Opportunity"
            buttonIcon={<Add />}
          />
        }
        pinnedColumns={{
          left: ["action", "name"]
        }}
      />
    </Container>
  );
};
